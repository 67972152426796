











import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AdminModule',
    created() {
        this.$store.state.routeLoaded = true;
    },
    methods: {
        install() {
            axios.get(this.$store.state.apiUrl + '/admin/module')
                .then(() => {
                    alert("The installation was a success!");
                })
                .catch(e => {
                    alert("There was an error. Check to see if the module is already there first and contact support for further assistance.")
                })
        }
    }
})
