














import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'SwitchPortal',
    data() {
        return {
            selectedPortal: this.$store.state.user.portalId + '%&' + this.$store.state.user.portalName
        }
    },
    methods: {
        async selectPortal() {
            const valSplit = this.selectedPortal.split('%&');
            const id = valSplit[0];
            const name = valSplit[1];
            const portal = this.$store.state.user.portals.find((portal: any) => {
                return portal.Name == name && portal.HSPortalId == id;
            });

            await axios.post(`${this.$store.state.apiUrl}/user/login/set-portal`, portal)
                    .catch(e => {
                        console.log(e);
                    });

            await axios.get(`${this.$store.state.apiUrl}/session`)
                .then(resp => {
                    this.$store.state.user = resp.data;
                    this.$store.state.signedIn = true;
                })
                .catch(e => {
                    console.log(e);
                });

            this.$emit('closeEdit');
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
    }
})
