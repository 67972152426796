







import Vue from 'vue';

export default Vue.extend({
    name: 'AdminDeals',
    data() {
        return {
            url: ''
        }
    },
    created() {
        this.url = `https://app.hubspot.com/contacts/${this.$store.state.user.portalId}/deals/list/view/all/`;
    },
    mounted() {
        this.$store.state.routeLoaded = true;
    }
})
