
































import Vue from 'vue';
import axios from 'axios';
import ConfirmDelete from '../../components/ConfirmDelete.vue';
import AddEditUser from '../../components/AddEditPartner.vue';

export default Vue.extend({
    name: 'AdminUsers',
    components: {
        ConfirmDelete: ConfirmDelete,
        AddEditUser: AddEditUser
    },
    data() {
        return {
            users: [],
            currentPageNum: 1 as number,
            searchQ: '',
            totalItems: 0,
            pagination: [] as number[],
            numItems: 10,
            showEditUser: false,
            showConfirmDelete: false,
            idToDelete: -1,
            userToEdit: null as any
        }
    },
    created() {
        this.getUsers();
    },
    methods: {
        async getUsers() {
            let url = `${this.$store.state.apiUrl}/admin/partners?pageNum=${this.currentPageNum}&numItems=${this.numItems}`;
            if(this.searchQ && this.searchQ.length > 2) {
                url += '&searchQ=' + this.searchQ;
            }
            await axios.get(url, { withCredentials: true })
                .then(resp => {
                    if(resp.data.users) {
                        this.users = resp.data.users;
                    } else {
                        this.users = [];
                    }
                    this.pagination = [];
                    this.totalItems = resp.data.total;
                    if(this.currentPageNum - 2 > 0) {
                        this.pagination.push(this.currentPageNum - 2);
                    }
                    if(this.currentPageNum - 1 > 0) {
                        this.pagination.push(this.currentPageNum - 1);
                    }
                    this.pagination.push(this.currentPageNum);
                    if((this.currentPageNum * this.numItems) < this.totalItems) {
                        this.pagination.push(this.currentPageNum + 1);
                    }
                    if(((this.currentPageNum+1) * this.numItems) < this.totalItems) {
                        this.pagination.push(this.currentPageNum + 2);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            this.$store.state.routeLoaded = true;
        },
        getUsersReset() {
            this.currentPageNum = 1;
            this.getUsers();
        },
        changePage(pageNum: number) {
            this.currentPageNum = pageNum;
            this.getUsers();
        },
        editUserWindow(user: any) {
            this.userToEdit = user;
            this.showEditUser = true;
        },
        closeEdit() {
            this.showEditUser = false;
            this.getUsers();
        },
        deleteUser(id: number) {
            console.log(id);
            this.idToDelete = id;
            this.showConfirmDelete = true;
        },
        confirmDeleteCB() {
            axios.delete(`${this.$store.state.apiUrl}/admin/partners/${this.idToDelete}`, { withCredentials: true })
                .then(resp => {
                    this.getUsers();
                    alert("Delete Successful");
                    this.showConfirmDelete = false;
                })
                .catch(e => {
                    this.showConfirmDelete = false;
                    alert(e.response.data);
                });
        },
        cancelDeleteCB() {
            this.idToDelete = -1;
            this.showConfirmDelete = false;
        }
    }
})
