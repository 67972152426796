





























import Vue from 'vue';
import axios from 'axios';
import InfoPop from '../components/InfoPop.vue';
import draggable from 'vuedraggable';

export default Vue.extend({
    name: 'AdminDealSettings',
    components: {
        InfoPop: InfoPop,
        draggable: draggable
    },
    data() {
        return {
            groups: [] as string[],
            isLoaded: false,
            input: '',
            disableButton: false
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/admin/videos/groups`)
            .then(resp => {
                resp.data.forEach((group: any) => {
                    this.groups.push(group.Name)
                })
            })
            .catch(e => {
                console.log(e);
            })
        this.isLoaded = true;
    },
    methods: {
        removeGroup(group: string) {
            this.groups = this.groups.filter(g => {
                return g != group;
            })
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
        addGroup() {
            if(this.groups.includes(this.input)) {
                alert('You cannot add the same group twice.')
            } else if(this.input.toLowerCase() == 'general') {
                alert('You cannot use the group name General, that is reserved for all of your partners.')
            } else {
                this.groups.push(this.input);
                this.input = '';
            }
        },
        async submit() {
            this.disableButton = true;
            await axios.post(`${this.$store.state.apiUrl}/admin/videos/groups`, this.groups)
                .then(resp => {
                    alert('Groups have been updated.');
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert('There was an error. Please try submitting again.');
                    this.disableButton = false;
                });
        }
    }
})
