





















import Vue from 'vue';
import axios from 'axios';
import ResetPasswordDialog from '../components/ResetPasswordDialog.vue';

export default Vue.extend({
    name: 'LogIn',
    components: {
        ResetPasswordDialog
    },
    data() {
        return {
            email: '',
            password: '',
            submitted: false,
            loginError: false,
            showReset: false,
            previousRoute: ''
        }
    },
    created() {
        this.$store.state.routeLoaded = true;
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            vm.previousRoute = from.path;
        })
    },
    methods: {
        async submit() {
            this.submitted = true;
            this.loginError = false;

            // Must sign out then sign in separate or server has session error
            await axios.get(`${this.$store.state.apiUrl}/signout`)
                .catch(e => {
                    console.log(e);
                })

            await axios.post(`${this.$store.state.apiUrl}/user/login`, { email: this.email, password: this.password})
                .catch(e => {
                    this.loginError = true;
                    this.submitted = false;
                });
            
            if(this.loginError) {
                return;
            }

            await axios.get(`${this.$store.state.apiUrl}/session`)
                .then(resp => {
                    this.$store.state.user = resp.data;
                    this.$store.state.signedIn = true;
                })
                .catch(e => {
                    if(e.response.data == "NO_PORTALS") {
                        alert("There are no portals associated with your log in at this time.")
                    }
                    this.submitted = false;
                });

            this.$store.state.signedIn = true;
            this.$router.push(this.previousRoute);
        }
    }
})
