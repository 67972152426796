







































































































































import Vue from 'vue';
import axios from 'axios';
import ConfirmDelete from '../../components/ConfirmDelete.vue';
import AddEditDeal from '../../components/AddEditDeal.vue';
import ViewDeal from '../../components/ViewDeal.vue';
import AddNote from '../../components/AddNote.vue';

export default Vue.extend({
    name: 'PartnerDeals',
    components: {
        ConfirmDelete: ConfirmDelete,
        AddEditDeal: AddEditDeal,
        ViewDeal: ViewDeal,
        AddNote: AddNote
    },
    data() {
        return {
            deals: [],
            loadingDeals: true,
            searchQ: '',
            pagination: [] as number[],
            showConfirmDelete: false,
            showEditDeal: false,
            editDealId: 'new',
            numItems: 10,
            currentPageNum: 1,
            idToDelete: -1,
            showViewDeal: false,
            viewDealId: -1,
            viewDealStage: '',
            addNoteId: -1,
            showAddNote: false,
            showAddNoteLinks: false,
            showDeleteDealLinks: false,
            columnTwoProperty: 'dealstage',
            columnThreeProperty: 'amount',
            columnFourProperty: null as any,
            columnFiveProperty: null as any,
            searchDebounceTimer: null as any,
            view: 'table',
            dealsByStageAll: null as any,
            dealsByStage: null as any,
            disableAddDeals: false,
            disableEditDeals: false,
            csvLoading: false,
            columnTwoLabel: '',
            columnThreeLabel: '',
            columnFourLabel: '',
            columnFiveLabel: '',
            sortBy: 'hs_lastmodifieddate',
            sortDirection: 'DESC',
            useCustomDealFilter: false,
            users: [] as any,
            pipelines: [] as any,
            pipelinesToView: [] as any,
            gridSelectedPipeline: null as any
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/partner/deals/settings?includeAllPipelines=true`)
            .then(resp => {
                this.disableAddDeals = resp.data.disableAddDeals;
                this.disableEditDeals = resp.data.disableEditDeals;
                this.showAddNoteLinks = resp.data.allowAddNotes;
                this.showDeleteDealLinks = resp.data.allowDeleteDeals;
                if(resp.data.columnThreeProperty) {
                    this.columnThreeProperty = resp.data.columnThreeProperty;
                    this.columnThreeLabel = resp.data.columnThreeLabel;
                }
                if(resp.data.columnTwoProperty) {
                    this.columnTwoProperty = resp.data.columnTwoProperty;
                    this.columnTwoLabel = resp.data.columnTwoLabel;
                }
                if(resp.data.columnFourProperty) {
                    this.columnFourProperty = resp.data.columnFourProperty;
                    this.columnFourLabel = resp.data.columnFourLabel;
                }
                if(resp.data.columnFiveProperty) {
                    this.columnFiveProperty = resp.data.columnFiveProperty;
                    this.columnFiveLabel = resp.data.columnFiveLabel;
                }
                this.pipelines = resp.data.pipelines;
                this.pipelinesToView = resp.data.pipelinesToView.sort((a: any, b: any) => {
                    if ( a.label < b.label ){
                        return -1;
                    } else if ( a.label > b.label ){
                        return 1;
                    }
                    return 0;
                });
                this.gridSelectedPipeline = this.pipelinesToView[0];
            })
            .catch(e => {
                console.log(e);
            })

        if(this.$store.state.user.portalId == 272855 && this.columnFiveProperty == 'vendor_targeted_focus_account') {
            this.sortBy = 'vendor_targeted_focus_account';
        }

        if(this.columnTwoProperty == 'hubspot_owner_id' || 
            this.columnThreeProperty == 'hubspot_owner_id' || 
            this.columnFourProperty == 'hubspot_owner_id' ||
            this.columnFiveProperty == 'hubspot_owner_id') {
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/hsusers`)
                .then(resp => {
                    this.users = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        }

        await this.getDeals();
        this.$store.state.routeLoaded = true;
    },
    methods: {
        async getDeals() {
            this.loadingDeals = true;        
            let url = `${this.$store.state.apiUrl}/partner/deals?numItems=${this.numItems}&after=${(this.currentPageNum - 1) * this.numItems}&sortBy=${this.sortBy}&sortDirection=${this.sortDirection}`;
            if(this.searchQ && this.searchQ.length > 2) {
                url += '&searchQ=' + this.searchQ;
            }
            if(this.useCustomDealFilter) {
                url += '&useCustomDealFilter=true';
            }
            await axios.get(url)
                .then(resp => {
                    this.deals = resp.data.results;
                    this.deals.forEach((deal: any) => {
                        this.setDealDisplayValues(deal);
                    })
                        
                    this.pagination = [];
                    if(this.currentPageNum - 1 > 0) {
                        this.pagination.push(this.currentPageNum - 1);
                    }
                    this.pagination.push(this.currentPageNum);
                    if(resp.data.paging) {
                        this.pagination.push(this.currentPageNum + 1);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            this.loadingDeals = false;
        },
        async getDealsByStage() {
            this.searchQ = '';
            this.loadingDeals = true;
            let url = `${this.$store.state.apiUrl}/partner/deals/allbystage?pipeline=${this.gridSelectedPipeline.id}`;
            if(this.useCustomDealFilter) {
                url += '&useCustomDealFilter=true';
            }
            await axios.get(url)
                .then(resp => {
                    this.dealsByStageAll = resp.data;
                    this.dealsByStageAll.forEach((stage: any) => {
                        stage.deals.forEach((deal: any) => {
                            this.setDealDisplayValues(deal);
                        })
                    })
                    this.dealsByStage = JSON.parse(JSON.stringify(this.dealsByStageAll));
                })
                .catch(e => {
                    console.log(e);
                });
            this.loadingDeals = false;
        },
        setDealDisplayValues(deal: any) {
            if(deal.properties.dealname.indexOf(' - ') > -1) {
                deal.displayName = deal.properties.dealname.replace(this.$store.state.user.name + ' - ', '')
                    .replace(' - ' + this.$store.state.user.name, '')
                    .replace(this.$store.state.user.name.replace(/ /g, '') + ' - ', '')
                    .replace(' - ' + this.$store.state.user.name.replace(/ /g, ''), '');
            } else {
                deal.displayName = deal.properties.dealname;
            }
            
            const setDealColumnDisplay = (deal: any, columnProp: any, dealColumnPropName: any) => {
                if(deal.properties[columnProp]) {
                    if(columnProp == 'amount' || 
                        columnProp == 'hs_tcv' || 
                        columnProp == 'hs_acv') {
                            const currency = deal.properties.deal_currency_code || this.$store.state.user.currency;
                            const amountSplit = deal.properties[columnProp].split('.');
                            deal[dealColumnPropName] = amountSplit[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                            if(amountSplit[1] && parseInt(amountSplit[1]) != 0) {
                                deal[dealColumnPropName] += '.' + amountSplit[1].substring(0,2);
                                if(amountSplit[1].length < 2) {
                                    deal[dealColumnPropName] += '0';
                                }
                            }
                            if(currency == 'EUR') {
                                deal[dealColumnPropName] = '€' + deal[dealColumnPropName];
                            } else if(currency == 'GBP') {
                                deal[dealColumnPropName] = '£' + deal[dealColumnPropName];
                            } else {
                                deal[dealColumnPropName] = '$' + deal[dealColumnPropName];
                            }
                    } else if(columnProp == 'closedate' || columnProp == 'createdate') {
                        deal[dealColumnPropName] = new Date(deal.properties[columnProp]).toLocaleDateString();
                    } else if(columnProp == 'hubspot_owner_id') {
                        const foundUser = this.users.find((u: any) => {
                            return u.id == deal.properties[columnProp];
                        }) as any;
                        if(foundUser) {
                            deal[dealColumnPropName] = foundUser.firstName + ' ' + foundUser.lastName;
                        }
                    } else if(columnProp == 'pipeline') {
                        try {
                            deal[dealColumnPropName] = this.pipelines.find((p: any) => {
                                return p.id == deal.properties[columnProp];
                            }).label
                        } catch(e) {
                            console.log(e);
                        }
                    } else {
                        deal[dealColumnPropName] = deal.properties[columnProp];
                    }
                }
            }
            setDealColumnDisplay(deal, this.columnTwoProperty, 'columnTwo');
            setDealColumnDisplay(deal, this.columnThreeProperty, 'columnThree');
            setDealColumnDisplay(deal, this.columnFourProperty, 'columnFour');
            setDealColumnDisplay(deal, this.columnFiveProperty, 'columnFive');
        },
        getDealsReset() {
            this.currentPageNum = 1;
            this.getDeals();
        },
        debounceDealsReset() {
            if(this.view == 'table') {
                clearTimeout(this.searchDebounceTimer);
                this.searchDebounceTimer = setTimeout(() => { this.getDealsReset() }, 500);
            } else {
                if(this.searchQ.length < 3) {
                    this.dealsByStage = JSON.parse(JSON.stringify(this.dealsByStageAll));
                } else {
                    const searchQLower = this.searchQ.toLowerCase();
                    this.dealsByStage.forEach((stage: any) => {
                        stage.deals = stage.deals.filter((deal: any) => {
                            return deal.displayName.toLowerCase().includes(searchQLower);
                        })
                    })
                }
            }
        },
        changePage(pageNum: number) {
            this.currentPageNum = pageNum;
            this.getDeals();
        },
        editDeal(id: any) {
            this.editDealId = id;
            this.showEditDeal = true;
        },
        closeEdit() {
            this.showEditDeal = false;
            this.loadingDeals = true;
            if(this.view == 'table') {
                setTimeout(() => { this.getDealsReset() }, 4500);
            } else {
                setTimeout(() => { this.getDealsByStage() }, 4500);
            }
        },
        confirmDelete(id: number) {
            this.showConfirmDelete = true;
            this.idToDelete = id;
        },
        confirmDeleteCB() {
            this.showConfirmDelete = true;
            axios.delete(`${this.$store.state.apiUrl}/partner/deals/${this.idToDelete}`)
                .then(resp => {
                    alert("Delete Successful");
                    this.showConfirmDelete = false;
                    this.loadingDeals = true;
                    if(this.view == 'table') {
                        setTimeout(() => { this.getDealsReset() }, 2000);
                    } else {
                        setTimeout(() => { this.getDealsByStage() }, 1000);
                    }
                })
                .catch(e => {
                    this.showConfirmDelete = false;
                    alert(e.response.data);
                })
        },
        cancelDeleteCB() {
            this.idToDelete = -1;
            this.showConfirmDelete = false;
        },
        viewDeal(id: number, stageLabel: string) {
            this.viewDealId = id;
            this.viewDealStage = stageLabel
            this.showViewDeal = true;
        },
        closeViewDeal() {
            this.showViewDeal = false;
        },
        addNote(id: number) {
            this.addNoteId = id;
            this.showAddNote = true;
        },
        closeAddNote() {
            this.showAddNote = false;
        },
        async changeView(view: string) {
            this.searchQ = '';
            this.loadingDeals = true;
            if(view == 'table') {
                await this.getDeals();
            } else {
                await this.getDealsByStage();
            }
            this.view = view;
            this.loadingDeals = false;
        },
        async generateCSV() {
            this.csvLoading = true;
            let url = `${this.$store.state.apiUrl}/partner/deals/csv?sortBy=${this.sortBy}&sortDirection=${this.sortDirection}`;
            if(this.useCustomDealFilter) {
                url += '&useCustomDealFilter=true';
            }
            await axios.get(url)
                .then(resp => {
                    window.open(`/deal_csv/${resp.data}`,'_blank');
                })
                .catch(e => {
                    alert('There was an error')
                })
            this.csvLoading = false;
        },
        changeSort(prop: string) {
            this.sortBy = prop;
            if(this.sortDirection == 'DESC') {
                this.sortDirection = 'ASC';
            } else {
                this.sortDirection = 'DESC';
            }
            this.getDealsReset();
        },
        customDealFilter() {
            this.useCustomDealFilter = !this.useCustomDealFilter;
            if(this.view == 'table') {
                this.getDealsReset();
            } else {
                this.getDealsByStage();
            }
        },
        checkNoteCondition(deal: any) {
            if(this.$store.state.user.portalId == 8614336 &&
                (deal.properties.dealstage == '7261452' ||
                deal.properties.dealstage == 'closedlost')) {
                    return false;
            }
            return true;
        },
        checkEditCondition(deal: any) {
            if(this.$store.state.user.portalId == 8614336 &&
                (deal.properties.dealstage == '7261452' ||
                deal.properties.dealstage == 'closedlost')) {
                    return false;
            }
            return true;
        }
    }
})
