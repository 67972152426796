

































import Vue from 'vue';
import axios from 'axios';
import AddEditCompany from '../../components/AddEditCompany.vue';
import ViewContactCompany from '../../components/ViewContactCompany.vue';

export default Vue.extend({
    name: 'PartnerCompanies',
    components: {
        AddEditCompany, ViewContactCompany
    },
    data() {
        return {
            companies: [],
            loadingCompanies: true,
            searchQ: '',
            pagination: [] as number[],
            numItems: 10,
            currentPageNum: 1,
            searchDebounceTimer: null as any,
            showAddCompany: false,
            editCompanyId: 'new',
            showViewCompany: false,
            viewCompanyId: -1
        }
    },
    async created() {
        await this.getCompanies();
        this.$store.state.routeLoaded = true;
    },
    methods: {
        async getCompanies() {
            this.loadingCompanies = true;        
            let url = `${this.$store.state.apiUrl}/partner/companies?numItems=${this.numItems}&after=${(this.currentPageNum - 1) * this.numItems}`;
            if(this.searchQ && this.searchQ.length > 2) {
                url += '&searchQ=' + this.searchQ;
            }
            await axios.get(url)
                .then(resp => {
                    this.companies = resp.data.results;
                        
                    this.pagination = [];
                    if(this.currentPageNum - 1 > 0) {
                        this.pagination.push(this.currentPageNum - 1);
                    }
                    this.pagination.push(this.currentPageNum);
                    if(resp.data.paging) {
                        this.pagination.push(this.currentPageNum + 1);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            this.loadingCompanies = false;
        },
        getCompaniesReset() {
            this.currentPageNum = 1;
            this.getCompanies();
        },
        debounceCompaniesReset() {
            clearTimeout(this.searchDebounceTimer);
            this.searchDebounceTimer = setTimeout(() => { this.getCompaniesReset() }, 500);
        },
        closeAddCompany() {
            this.showAddCompany = false;
            this.loadingCompanies = true;
            setTimeout(() => { this.getCompaniesReset() }, 4500);
        },
        changePage(pageNum: number) {
            this.currentPageNum = pageNum;
            this.getCompanies();
        },
    }
})
