















import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AddSchema',
    data() {
        return {
            formData: {
                singular: '',
                plural: ''
            },
            disableButton: false
        }
    },
    methods: {
        async submit() {
            if(!this.formData.singular || !this.formData.plural) {
                window.alert("All fields are required.")
                this.disableButton = false;
                return;
            }
            this.disableButton = true;

            await axios.post(`${this.$store.state.apiUrl}/admin/partnerobjects/schema`, this.formData)
                .then(resp => {
                    this.$emit('closeAddSchema', resp.data, this.formData.singular, 'name');
                })
                .catch(e => {
                    alert("Your portal must have Enterprise in order to use Custom Objects.");
                })

            this.disableButton = false;
        },
        closeEdit() {
            this.$emit('closeAddSchema');
        }
    }
})
