



























import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AddEditDocument',
    props: ['document'],
    data() {
        return {
            formData: {
                documentId: -1,
                name: '',
                url: '',
                documentGroup: 'General'
            },
            isNew: true,
            isLoaded: false,
            disableButton: false,
            HSDocs: [],
            searchQ: '',
            groups: [] as string[]
        }
    },
    async created() {
        if(this.$props.document.DocumentId != 'new') {
            this.isNew = false;
            this.formData.documentId = this.$props.document.DocumentId;
            this.formData.name = this.$props.document.Name;
            this.formData.url = this.$props.document.URL;
            this.formData.documentGroup = this.$props.document.DocumentGroup;
        }
        await axios.get(`${this.$store.state.apiUrl}/admin/documents/groups`)
            .then(resp => {
                resp.data.forEach((group: any) => {
                    this.groups.push(group.Name)
                })
            })
            .catch(e => {
                console.log(e);
            })
        this.isLoaded = true;
    },
    methods: {
        async submit() {
            this.disableButton = true;
            if(!(this.formData as any).name) {
                alert("Name is required.");
                this.disableButton = false;
                return;
            }
            if(!(this.formData as any).url) {
                alert("URL is required.");
                this.disableButton = false;
                return;
            }

            let url = `${this.$store.state.apiUrl}/admin/documents`;
            if(!this.isNew) {
                url += `?id=${this.formData.documentId}`;
            } 
            await axios.post(url, this.formData)
                .then(() => {
                    if(this.isNew) {
                        alert("Document added.");
                    } else {
                        alert("Document updated.");
                    }
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert(e.response.data);
                })
            this.disableButton = false;
        },
        searchDocuments() {
            axios.get(`${this.$store.state.apiUrl}/admin/documents/hs?searchQ=${this.searchQ}`)
                .then(resp => {
                    this.HSDocs = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        },
        selectDocument(url: string) {
            this.formData.url = url;
        },
        closeEdit() {
            this.$emit('closeEdit');
        }
    }
})
