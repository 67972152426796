



























































































import Vue from 'vue';
import axios from 'axios';
import ConfirmDelete from '../../components/ConfirmDelete.vue';

export default Vue.extend({
    name: 'Payment',
    components: {
        ConfirmDelete: ConfirmDelete
    },
    data() {
        return {
            disableButton: false,
            errorMsg: '',
            showErrorMsg: false,
            cardInput: '',
            phoneInput: '',
            paymentMessage: '',
            isValid: true,
            showConfirmDelete: false,
            showChangePlan: false,
            disablePlanChange: false
        }
    },
    async mounted() {
        await axios.get(this.$store.state.apiUrl + '/admin/payment/status')
            .then(resp => {
                if(resp.data.status != 'active') {
                    this.isValid = false;
                }
                if(resp.data.userCount > 10) {
                    this.disablePlanChange = true;
                    (this.$refs.tier as HTMLInputElement).value = '2';
                }
                this.paymentMessage = resp.data.status;
            })
            .catch(e => {
                this.isValid = false;
                this.paymentMessage = e.response.data;
            })
        this.$store.state.routeLoaded = true;
    },
    methods: {
        submit() {
            if(this.disableButton) {
                return;
            }
            this.disableButton = true;
            const data = {
                email: (this.$refs.email as HTMLInputElement).value,
                firstName: (this.$refs.firstName as HTMLInputElement).value,
                lastName: (this.$refs.lastName as HTMLInputElement).value,
                phone: this.phoneInput.replace(/()-/g, ''),
                address: (this.$refs.address as HTMLInputElement).value,
                city: (this.$refs.city as HTMLInputElement).value,
                state: (this.$refs.state as HTMLInputElement).value,
                zip: (this.$refs.zip as HTMLInputElement).value,
                card: this.cardInput.replace(/-/g, ''),
                expiration: (this.$refs.expiration as HTMLInputElement).value,
                cvc: (this.$refs.cvc as HTMLInputElement).value,
                tier: (this.$refs.tier as HTMLInputElement).value,
                hsPortalId: this.$store.state.portalId
            };
            
            let isError = false;
            Object.keys(data).forEach(key => {
                if(key == 'hsPortalId' || key == "phone") return;
                if(!(data as any)[key] && this.$refs[key]) {
                    isError = true;
                    (this.$refs[key] as any).classList.value = "input-error";
                } else {
                    (this.$refs[key] as any).classList.value = "";
                }
            });
            this.errorMsg = "Please fill out all fields";
            if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                .test(data.email)) {
                    isError = true;
                    (this.$refs.email as any).classList.value = "input-error";
                    this.errorMsg = 'Must use a valid email';
            } else {
                (this.$refs.email as any).classList.value = "";
            }
            if(!(this.$refs.agree as any).checked){
                this.errorMsg = 'Must acknowledge this agreement by checking the box';
                isError = true;
            }
            if(isError){
                this.showErrorMsg = true;
                this.disableButton = false;
                return;
            }

            axios.post(this.$store.state.apiUrl + '/admin/payment', data)
                .then(resp => {
                    alert('Payment Updated Successfully!');
                    this.$store.state.user.subscriptionActive = true;
                    this.$router.push('/');
                })
                .catch(e => {
                    if(e.response?.data == 'INSTALL_ERROR') {
                        alert('Payment Updated Successfully!');
                        this.$store.state.user.subscriptionActive = true;
                        this.$router.push('/');
                        // alert('Your Payment has processed successfully, but there was an error installing the module to your portal. You can try reinstalling by going to Reinstall Module in the upper right menu.');
                    } else {
                        alert(e.response?.data);
                    }
                    this.disableButton = false;
                });
        },
        formatToPhone() {
            // Remove characters
            let newVal = this.phoneInput.replace(/[^0-9]/g, "");
            const input = newVal.replace(/\D/g,'').substring(0,10); // First ten digits of input only
            const zip = input.substring(0,3);
            const middle = input.substring(3,6);
            const last = input.substring(6,10);

            if(input.length > 6){newVal = zip+'-'+middle+'-'+last;}
            else if(input.length > 3){newVal = zip+'-'+middle;}
            else if(input.length > 0){newVal = zip;}
            this.phoneInput = newVal;
        },

        formatToCard() {
            // Remove characters
            let newVal = this.cardInput.replace(/[^0-9]/g, "");
            const input = newVal.replace(/\D/g,'');
            const first = input.substring(0,4);
            const second = input.substring(4,8);
            const third = input.substring(8,12);
            const last = input.substring(12,16);
            if(input.length < 5) newVal = first;
            else if(input.length < 9) newVal = first + '-' + second;
            else if(input.length < 13) newVal = first + '-' + second + '-' + third;
            else if(input.length >= 13) newVal = first + '-' + second + '-' + third + '-' + last;
            this.cardInput = newVal;
        },
        cancelDeleteCB() {
            this.showConfirmDelete = false;
        },
        confirmDeleteCB() {
            axios.delete(this.$store.state.apiUrl + '/admin/payment')
                .then(() => {
                    axios.get(this.$store.state.apiUrl + '/signout')
                        .then(() => {
                            window.location.href = this.$store.state.apiUrl + '/oauth';
                        })
                        .catch(e => {
                            console.log(e);
                        })
                })
                .catch(e => {
                    alert(e.response.data);
                    this.showConfirmDelete = false;
                })
        },
        submitChangePlan() {
            const tier = (this.$refs.changeplantier as HTMLInputElement).value;
            this.disableButton = true;
            axios.post(this.$store.state.apiUrl + '/admin/payment/changeplan', { tier: tier })
                .then(resp => {
                    alert('Plan Updated Successfully!');
                    this.$store.state.user.subscriptionActive = true;
                    this.$router.push('/');
                })
                .catch(e => {
                    alert(e.response.data);
                    this.disableButton = false;
                });
        }
    }
})
