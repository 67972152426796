
















































































































































































































































import Vue from 'vue';
import axios from 'axios';
import AddSchema from '../../components/AddSchema.vue';
import InfoPop from '../../components/InfoPop.vue';

export default Vue.extend({
    name: 'AdminSettings',
    components: {
        AddSchema: AddSchema,
        InfoPop: InfoPop
    },
    data() {
        return {
            formData: {
                name: '',
                logoURL: '',
                contactFormId: '',
                allowLineItems: false,
                allowLineItemCustomPrice: false,
                allowViewNotes: false,
                allowAddNotes: false,
                allowDeleteDeals: false,
                partnerObjectId: null,
                partnerObjectPrimaryNameField: '',
                portalColor: '',
                defaultStageCheck: false,
                defaultStageValue: null,
                requireLineItems: false,
                columnTwoProperty: 'dealstage',
                columnThreeProperty: 'amount',
                columnFourProperty: '',
                columnFiveProperty: '',
                allowDeals: false,
                allowLeads: false,
                partnerNameAtEnd: false,
                associatePartnerContactWithDeal: true,
                shareDocuments: false,
                shareVideos: false,
                allowViewEmails: false,
                allowViewCalls: false,
                allowViewMeetings: false
            },
            formName: '',
            searchedForms: [],
            formSearchQ: '',
            disableButton: false,
            schemas: [],
            selectedSchemaName: null,
            showSchemas: false,
            showAddSchema: false
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/admin/partnerobjects/schemas`)
            .then(resp => {
                this.schemas = resp.data;
            })
            .catch(e => {
                console.log(e);
            })
        this.formData.name = this.$store.state.user.name;
        this.formData.logoURL = this.$store.state.user.logoURL;
        this.searchForms();
        await this.getSettings();
        this.$store.state.routeLoaded = true;
        // TO DO this will probably require an axios call for more info
    },
    methods: {
        async getSettings() {
            await axios.get(`${this.$store.state.apiUrl}/admin/settings`)
                .then(resp => {
                    console.log(resp.data)
                    if(resp.data.form) {
                        this.formName = resp.data.form.name;
                        this.formData.contactFormId = resp.data.form.guid;
                    }
                    this.formData.allowLineItems = resp.data.allowLineItems;
                    this.formData.allowViewNotes = resp.data.allowViewNotes;
                    this.formData.allowAddNotes = resp.data.allowAddNotes;
                    this.formData.partnerObjectId = resp.data.partnerObjectId;
                    this.formData.partnerObjectPrimaryNameField = resp.data.partnerObjectPrimaryNameField;
                    this.formData.portalColor = resp.data.portalColor;
                    this.formData.allowDeleteDeals = resp.data.allowDeleteDeals;
                    this.formData.requireLineItems = resp.data.requireLineItems;
                    this.formData.allowLineItemCustomPrice = resp.data.allowLineItemCustomPrice;
                    this.formData.allowDeals = resp.data.allowDeals;
                    this.formData.allowLeads = resp.data.allowLeads;
                    this.formData.partnerNameAtEnd = resp.data.partnerNameAtEnd;
                    this.formData.associatePartnerContactWithDeal = resp.data.associatePartnerContactWithDeal;
                    this.formData.shareDocuments = resp.data.shareDocuments;
                    this.formData.shareVideos = resp.data.shareVideos;
                    this.formData.allowViewEmails = resp.data.allowViewEmails;
                    this.formData.allowViewCalls = resp.data.allowViewCalls;
                    this.formData.allowViewMeetings = resp.data.allowViewMeetings;
                    if(resp.data.columnTwoProperty) {
                        this.formData.columnTwoProperty = resp.data.columnTwoProperty;
                    }
                    if(resp.data.columnThreeProperty) {
                        this.formData.columnThreeProperty = resp.data.columnThreeProperty;
                    }
                    if(resp.data.columnFourProperty) {
                        this.formData.columnFourProperty = resp.data.columnFourProperty;
                    }
                    if(resp.data.columnFiveProperty) {
                        this.formData.columnFiveProperty = resp.data.columnFiveProperty;
                    }
                    if(resp.data.defaultDealStage) {
                        this.formData.defaultStageCheck = true;
                        this.formData.defaultStageValue = resp.data.defaultDealStage;
                    }
                    if(this.formData.partnerObjectId) {
                        this.showSchemas = true;
                        this.selectedSchemaName = (this.schemas as any).find((schema: any) => {
                            return schema.objectId == this.formData.partnerObjectId;
                        }).name;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
        async submit() {
            if(!this.formData.defaultStageCheck) {
                this.formData.defaultStageValue = null;
            }
            this.disableButton = true;
            await axios.post(`${this.$store.state.apiUrl}/admin/settings`, this.formData)
                .then(async () => {
                    await axios.get(`${this.$store.state.apiUrl}/session`)
                        .then(resp => {
                            this.$store.state.user = resp.data;
                        })
                        .catch(e => {
                            console.log(e);
                        })
                    alert("Information updated");
                })
                .catch(e => {
                    alert('There was an error');
                });
            this.disableButton = false;      
        },
        searchForms() {
            axios.get(`${this.$store.state.apiUrl}/admin/forms?searchQ=${this.formSearchQ}`)
                .then(resp => {
                    this.searchedForms = resp.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        selectForm(form: any) {
            this.formName = form.name;
            this.formData.contactFormId = form.guid;
        },
        removeForm() {
            this.formName = '';
            (this.formData.contactFormId as any) = null;
        },
        selectSchema(schema: any) {
            this.formData.partnerObjectId = schema.objectId;
            this.selectedSchemaName = schema.name;
        },
        removeSchema() {
            this.formData.partnerObjectId = null;
            this.selectedSchemaName = null;
        },
        closeAddSchema(id: any, name: any, primaryField: any) {
            this.formData.partnerObjectId = id;
            this.selectedSchemaName = name;
            this.formData.partnerObjectPrimaryNameField = primaryField;
            console.log(id, name, primaryField);
            this.showAddSchema = false;
            axios.get(`${this.$store.state.apiUrl}/admin/partnerobjects/schemas`)
                .then(resp => {
                    this.schemas = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        }
    }
})
