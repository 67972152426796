










import Vue from 'vue';

export default Vue.extend({
    name: 'ConfirmDelete',
    props: ['msg'],
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        confirm() {
            this.$emit('confirm');
        }
    }
})
