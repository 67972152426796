import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000',
    axios: axios.create({
      withCredentials: true
    }),
    routeLoaded: false,
    showErrorMsg: false,
    errorMsg: 'There was an error.',
    user: {},
    signedIn: false,
    showAdminLogIn: true,
    sleep: (ms: number) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  mutations: {
  },
  actions: {
    logOut() {
      axios.get(`${this.state.apiUrl}/signout`)
        .then(() => {
          if((this.state.user as any).isHSAdmin) {
            window.location.replace(`${this.state.apiUrl}/oauth`)
          } else {
            const previousColor = (this.state.user as any).portalColor;
            this.state.user = {};
            (this.state.user as any).portalColor = previousColor;
            this.state.signedIn = false;
            router.push('/login');
          }
        })
        .catch(e => {
          console.log(e);
        })
    },
    toggleInfoPop(context, event) {
      console.log("HERE");
      console.log(event);
    }
  },
  modules: {
  }
})
