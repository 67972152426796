














import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AddNote',
    props: ['id'],
    data() {
        return {
            disableButton: false,
            formData: {
                body: ''
            },
            file: null
        }
    },
    created() {
        console.log(this.$props.id);
    },
    methods: {
        /* async submit() {
            if(!this.formData.body) {
                alert('Cannot submit a blank note');
                return;
            }
            
            this.disableButton = true;
            await axios.post(`${this.$store.state.apiUrl}/partner/deals/note/${this.$props.id}`, this.formData)
                .then(() => {
                    alert('Note added');
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert(e.response.data);
                });

            this.disableButton = false;
        }, */
        async submit() {
            if(!this.formData.body) {
                alert('Cannot submit a blank note');
                return;
            }

            const mpFormData = new FormData();
            mpFormData.append('body', this.formData.body);
            if(this.file) {
                mpFormData.append('file', this.file as any);
            }
            
            this.disableButton = true;
            await axios.post(`${this.$store.state.apiUrl}/partner/deals/note/${this.$props.id}`, mpFormData, {headers: { 'Content-Type': 'multipart/form-data' }})
                .then(() => {
                    alert('Note added');
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert(e.response.data);
                });

            this.disableButton = false;
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
        onFileChange(e: any) {
            const files = e.target.files || e.dataTransfer.files;
            if(!files.length) {
                this.file = null;
            } else {
                this.file = files[0];
            }
        }
    }
})
