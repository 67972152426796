
























import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'UpdateCredentialsDialog',
    data() {
        return {
            disableButton: false,
            formData: {
                password: ''
            },
            confirmPassword: '',
            passwordsDoNotMatch: false,
            insufficientPassword: false
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeUpdateCredentials');
        },
        submit() {
            if(!this.formData.password) {
                return;
            }
            if(this.passwordsDoNotMatch || this.insufficientPassword) {
                return;
            }
            axios.post(`${this.$store.state.apiUrl}/user/login/updateuser`, this.formData)
                .then(resp => {
                    this.$emit('closeUpdateCredentials');
                })
                .catch(e => {
                    alert("There was an error");
                });
        },
        checkPassword() {
            if(this.formData.password != this.confirmPassword) {
                this.passwordsDoNotMatch = true;
            } else {
                this.passwordsDoNotMatch = false;
            }
            if(!/^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!@#$%^&*])[0-9a-zA-Z!@#$%^&*]{8,}$/.test(this.formData.password)) {
                this.insufficientPassword = true;
            } else {
                this.insufficientPassword = false;
            }
        }
    }
})
