












import Vue from 'vue';

export default Vue.extend({
    name: 'AddEditPropertyDescription',
    props: ['property'],
    methods: {
        closeEdit() {
            this.$emit('closeEdit');
        }
    }
})
