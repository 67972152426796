

































import Vue from 'vue';
import axios from 'axios';
import AddEditContact from '../../components/AddEditContact.vue';
import ViewContactCompany from '../../components/ViewContactCompany.vue';

export default Vue.extend({
    name: 'PartnerContacts',
    components: {
        AddEditContact, ViewContactCompany
    },
    data() {
        return {
            contacts: [],
            loadingContacts: true,
            searchQ: '',
            pagination: [] as number[],
            numItems: 10,
            currentPageNum: 1,
            searchDebounceTimer: null as any,
            showAddContact: false,
            editContactId: 'new',
            showViewContact: false,
            viewContactId: -1
        }
    },
    async created() {
        await this.getContacts();
        this.$store.state.routeLoaded = true;
    },
    methods: {
        async getContacts() {
            this.loadingContacts = true;        
            let url = `${this.$store.state.apiUrl}/partner/contacts?numItems=${this.numItems}&after=${(this.currentPageNum - 1) * this.numItems}`;
            if(this.searchQ && this.searchQ.length > 2) {
                url += '&searchQ=' + this.searchQ;
            }
            await axios.get(url)
                .then(resp => {
                    this.contacts = resp.data.results;
                        
                    this.pagination = [];
                    if(this.currentPageNum - 1 > 0) {
                        this.pagination.push(this.currentPageNum - 1);
                    }
                    this.pagination.push(this.currentPageNum);
                    if(resp.data.paging) {
                        this.pagination.push(this.currentPageNum + 1);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            this.loadingContacts = false;
        },
        getContactsReset() {
            this.currentPageNum = 1;
            this.getContacts();
        },
        debounceContactsReset() {
            clearTimeout(this.searchDebounceTimer);
            this.searchDebounceTimer = setTimeout(() => { this.getContactsReset() }, 500);
        },
        closeAddcontact() {
            this.showAddContact = false;
            this.loadingContacts = true;
            setTimeout(() => { this.getContactsReset() }, 4500);
        },
        changePage(pageNum: number) {
            this.currentPageNum = pageNum;
            this.getContacts();
        },
        viewContact(id: number) {
            this.viewContactId = id;
            this.showViewContact = true;
        }
    }
})
