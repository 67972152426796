

















































import Vue from 'vue';
import axios from 'axios';
import AddEditCompany from './AddEditCompany.vue';

export default Vue.extend({
    name: 'AddEditContact',
    props: ['id'],
    components: {
        AddEditCompany
    },
    data() {
        return {
            properties: [],
            users: [],
            formData: {
                properties: {
                    firstname: '',
                    lastname: '',
                    email: '',
                },
                associatedCompany: null
            },
            isLoaded: false,
            disableButton: false,
            showAddCompany: false,
            associatedCompanyName: '',
            searchCompanyDebounceTimer: null as any,
            companySearchQ: '',
            searchedCompanies: [],
            isNew: true
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/partner/contacts/properties`)
            .then(resp => {
                this.properties = resp.data.contactProperties;
            })
            .catch(e => {
                console.log(e);
            });

        let hasHSUserField = false;
        for(const prop of this.properties) {
            if((prop as any).fieldType == 'hsuser') {
                hasHSUserField = true;
                break;
            }
        }
        if(hasHSUserField) {
            await axios.get(`${this.$store.state.apiUrl}/partner/contacts/hsusers`)
                .then(resp => {
                    this.users = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        }

        this.searchCompanies();

        if(this.$props.id != 'new') {
            this.isNew = false;
            await axios.get(`${this.$store.state.apiUrl}/partner/contacts/${this.$props.id}`)
                .then(resp => {
                    if(resp.data.associatedCompany) {
                        this.formData.associatedCompany = resp.data.associatedCompany.id;
                        this.associatedCompanyName = resp.data.associatedCompany.properties.name;
                    }
                    
                    (this.formData.properties as any).firstname = resp.data.properties.firstname;
                    (this.formData.properties as any).lastname = resp.data.properties.lastname;
                    (this.formData.properties as any).email = resp.data.properties.email;
                    this.properties.forEach((prop: any) => {
                        if(resp.data.properties[prop.name]) {
                            if(prop.fieldType == "checkbox") {
                                (this.formData as any).properties[prop.name] = [];
                                const checkedVals = resp.data.properties[prop.name].split(';');
                                checkedVals.forEach((cv: any) => {
                                    if(this.$refs[prop.name+'_'+cv] && (this.$refs[prop.name+'_'+cv] as any)[0]) {
                                        (this.formData as any).properties[prop.name].push(cv);
                                        (this.$refs[prop.name+'_'+cv] as any)[0].checked = true;
                                    }
                                });
                            } else if(prop.fieldType == "date" && (this.$refs[prop.name] as any)[0]) {
                                try {
                                    this.setDate(resp.data.properties[prop.name].split("T")[0], prop.name);
                                    (this.$refs[prop.name] as any)[0].value = resp.data.properties[prop.name].split("T")[0];
                                } catch(e) { 
                                    console.log(e);
                                }
                            } else {
                                (this.formData.properties as any)[prop.name] = resp.data.properties[prop.name];
                            }
                        }
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        }
        
        this.isLoaded = true;
    },
    methods: {
        async submit() {
            this.disableButton = true;
            if(!(this.formData as any).properties.firstname) {
                alert("First name is required.");
                this.disableButton = false;
                return;
            }
            if(!(this.formData as any).properties.lastname) {
                alert("Last name is required.");
                this.disableButton = false;
                return;
            }
            if(!(this.formData as any).properties.email) {
                alert("Email is required.");
                this.disableButton = false;
                return;
            }
            for(const prop of this.properties) {
                if((prop as any).required && !(this.formData as any).properties[(prop as any).name]) {
                    alert("Please fill in all required fields.");
                    this.disableButton = false;
                    return;
                }
            }

            let url = `${this.$store.state.apiUrl}/partner/contacts`;
            if(!this.isNew) {
                url += `?id=${this.$props.id}`;
            } 
            await axios.post(url, this.formData)
                .then(() => {
                    if(this.isNew) {
                        alert("Contact added.");
                    } else {
                        alert("Contact updated.");
                    }  
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert(e.response.data);
                    this.disableButton = false;
                })
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
        multiSelectChange(propName: any, event: any) {
            if(!(this.formData as any).properties[propName]) {
                (this.formData as any).properties[propName] = [];
            }
            if(event.srcElement.checked) {
                (this.formData as any).properties[propName].push(event.srcElement.value);
            } else {
                (this.formData as any).properties[propName] = (this.formData as any).properties[propName].filter((option: any) => {
                    return option != event.srcElement.value;
                })
            }
        },
        setDate(dateString: string, propName: string) {
            const date = new Date(dateString);
            const dateUTC =  date.getTime();
            (this.formData as any).properties[propName] = dateUTC;
        },
        closeAddCompany(id: any, name: any) {
            this.showAddCompany = false;
            if(id) {
                (this.formData.associatedCompany as any) = id;
                this.associatedCompanyName = name;
            }
        },
        removeCompany() {
            (this.formData.associatedCompany as any) = null;
            this.associatedCompanyName = '';
        },
        debounceSearchCompanies() {
            clearTimeout(this.searchCompanyDebounceTimer);
            this.searchCompanyDebounceTimer = setTimeout(() => { this.searchCompanies() }, 500);
        },
        searchCompanies() {
            if(this.companySearchQ.length > 0) {
                axios.get(`${this.$store.state.apiUrl}/partner/companies/search?limit=30&searchQ=${this.companySearchQ}`)
                    .then(resp => {
                        this.searchedCompanies = resp.data.results;
                    })
                    .catch(e => {
                        console.log(e);
                    })
            } else {
                axios.get(`${this.$store.state.apiUrl}/partner/companies?limit=30`)
                    .then(resp => {
                        this.searchedCompanies = resp.data.results;
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },
    }
})
