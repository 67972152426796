









import Vue from 'vue';

export default Vue.extend({
    name: 'Contact',
    components: {
        
    },
    data() {
        return {
            
        }
    },
    mounted() {
        if(this.$store.state.user.isHSAdmin) {
            (window as any).hbspt.forms.create({
                portalId: "249181",
                formId: "3c7ab43f-8922-4b37-a705-ca46dfd38b3c",
                target: '#contact-form'
            });
        } else {
            (window as any).hbspt.forms.create({
                portalId: this.$store.state.user.portalId,
                formId: this.$store.state.user.contactFormId,
                target: '#contact-form'
            });
        }
        setTimeout(() => {
            this.$store.state.routeLoaded = true;
        }, 800);
    },
    methods: {

    }
})
