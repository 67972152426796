











































import Vue from 'vue';
import axios from 'axios';
import ConfirmDelete from '../components/ConfirmDelete.vue';
import AddEditDocument from '../components/AddEditDocument.vue';
import AddEditDocumentGroups from '../components/AddEditDocumentGroups.vue';

export default Vue.extend({
    name: 'Documents',
    components: {
        ConfirmDelete: ConfirmDelete,
        AddEditDocument: AddEditDocument,
        AddEditDocumentGroups: AddEditDocumentGroups
    },
    data() {
        return {
            documents: [],
            stageLabels: {},
            searchQ: '',
            pagination: [] as number[],
            showConfirmDelete: false,
            idToDelete: -1,
            showEditDocument: false,
            documentToEdit: { 
                DocumentId: 'new',
                Name: '',
                URL: '',
                DocumentGroup: ''
            },
            numItems: 10,
            currentPageNum: 1,
            totalItems: 0,
            showEditGroups: false,
            groups: ['General'],
            group: 'General'
        }
    },
    async created() {
        await this.getDocuments();
        this.getDocumentGroups();
        this.$store.state.routeLoaded = true;
    },
    methods: {
        async getDocuments() {
            let url = `${this.$store.state.apiUrl}/partner/documents?pageNum=${this.currentPageNum}&numItems=${this.numItems}&group=${this.group}`;
            if(this.$store.state.user.isHSAdmin) {
                url = `${this.$store.state.apiUrl}/admin/documents?pageNum=${this.currentPageNum}&numItems=${this.numItems}&group=${this.group}`;
            }
            if(this.searchQ && this.searchQ.length > 2) {
                url += '&searchQ=' + this.searchQ;
            }
            await axios.get(url, { withCredentials: true })
                .then(resp => {
                    this.documents = resp.data.documents;
                        
                    this.pagination = [];
                    this.totalItems = resp.data.total;
                    if(this.currentPageNum - 2 > 0) {
                        this.pagination.push(this.currentPageNum - 2);
                    }
                    if(this.currentPageNum - 1 > 0) {
                        this.pagination.push(this.currentPageNum - 1);
                    }
                    this.pagination.push(this.currentPageNum);
                    if((this.currentPageNum * this.numItems) < this.totalItems) {
                        this.pagination.push(this.currentPageNum + 1);
                    }
                    if(((this.currentPageNum+1) * this.numItems) < this.totalItems) {
                        this.pagination.push(this.currentPageNum + 2);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
        getDocumentGroups() {
            this.groups = ['General'];
            let url = `${this.$store.state.apiUrl}/partner/documents/groups`;
            if(this.$store.state.user.isHSAdmin) {
                url = `${this.$store.state.apiUrl}/admin/documents/groups`;
            }
            axios.get(url, { withCredentials: true})
                .then(resp => {
                    resp.data.forEach((group: any) => {
                        this.groups.push(group.Name);
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        },
        getDocumentsReset() {
            this.currentPageNum = 1;
            this.getDocuments();
        },
        changePage(pageNum: number) {
            this.currentPageNum = pageNum;
            this.getDocuments();
        },
        editDocument(document: any) {
            if(document == 'new') {
                (this.documentToEdit as any) = { 
                    DocumentId: 'new',
                    Name: '',
                    URL: '',
                    DocumentGroup: ''
                }
            } else {
                this.documentToEdit = document;
            }
            this.showEditDocument = true;
        },
        closeEdit() {
            this.showEditDocument = false;
            setTimeout(() => { this.getDocumentsReset() }, 500);
        },
        confirmDelete(id: number) {
            this.showConfirmDelete = true;
            this.idToDelete = id;
        },
        confirmDeleteCB() {
            this.showConfirmDelete = true;
            axios.delete(`${this.$store.state.apiUrl}/admin/documents/${this.idToDelete}`)
                .then(resp => {
                    alert("Delete Successful");
                    this.showConfirmDelete = false;
                    this.getDocumentsReset();
                })
                .catch(e => {
                    this.showConfirmDelete = false;
                    alert(e.response.data);
                })
        },
        cancelDeleteCB() {
            // this.idToDelete = -1;
            this.showConfirmDelete = false;
        }
    }
})
