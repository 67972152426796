





























import Vue from 'vue';
import axios from 'axios';
import Chart from 'chart.js/auto';

export default Vue.extend({
    name: 'Stats',
    data() {
        return {
            potentialRevenue: 0,
            wonRevenue: 0,
            lostRevenue: 0,
            startInput: new Date().getFullYear() + '-01-01',
            endInput: new Date().toISOString().split('T')[0],
            stagesChart: null as any,
            revenueChart: null as any,
            closedWonChart: null as any,
            months: {
                1: 'January',
                2: 'February',
                3: 'March',
                4: 'April',
                5: 'May',
                6: 'June',
                7: 'July',
                8: 'August',
                9: 'September',
                10: 'October',
                11: 'November',
                12: 'December'
            },
            currencySymbol: '$'
        }
    },
    async created() {
        if(this.$store.state.user.currency) {
            if(this.$store.state.user.currency == 'EUR') {
                this.currencySymbol = '€';
            } else if(this.$store.state.user.currency == 'GBP') {
                this.currencySymbol = '£';
            }
        }
        await this.loadStats();
        this.$store.state.routeLoaded = true;
    },
    methods: {
        async loadStats() {
            const currencySymbol = this.currencySymbol;

            if(this.stagesChart) {
                this.stagesChart.destroy();
            }
            if(this.revenueChart) {
                this.revenueChart.destroy();
            }
            if(this.closedWonChart) {
                this.closedWonChart.destroy();
            }

            let statsData = {} as any;
            const startInputSplit = this.startInput.split('-');
            const endInputSplit = this.endInput.split('-');
            const startInputString = startInputSplit[1] + '/' + startInputSplit[2] + '/' + startInputSplit[0];
            const endInputString = endInputSplit[1] + '/' + endInputSplit[2] + '/' + endInputSplit[0];
            const start = new Date(startInputString).getTime();
            const end = new Date(endInputString).getTime();

            let isError = false;
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/stats?start=${start}&end=${end}`)
                .then(resp => {
                    statsData = resp.data;
                    this.potentialRevenue = resp.data.potentialRevenue ? resp.data.potentialRevenue : 0;
                    this.wonRevenue = resp.data.wonRevenue ? resp.data.wonRevenue : 0;
                    this.lostRevenue = resp.data.lostRevenue ? resp.data.lostRevenue : 0;
                })
                .catch(e => {
                    isError = true;
                    console.log(e);
                });

            if(isError) {
                alert('There was an error.')
                return;
            }

            console.log(statsData);

            const colors = ["rgb(164, 211, 152)", "rgb(129, 193, 253)", "#E52525", "rgb(245, 199, 142)",
                "rgb(254, 165, 142)", "#D081D0", "rgb(81, 211, 217)"];

            // Deal Stages Bar
            const stagesDatasets = [] as any;

            for (const [key, value] of Object.entries(statsData.stageAmounts)) {
                // if((value as any).probability > 0 && (value as any).probability < 1) {
                    stagesDatasets.push({
                        label: key,
                        data: [ parseInt('' + (value as any).numDeals) ],
                        displayOrder: (value as any).displayOrder
                    })
                // }
            }
            stagesDatasets.sort((a: any, b: any) => {
                if(a.displayOrder > b.displayOrder) {
                    return 1;
                } else if(a.displayOrder < b.displayOrder) {
                    return -1;
                }
                return 0;
            })

            stagesDatasets.forEach((ds: any, i: number) => {
                ds.backgroundColor = colors[i % 7];
                ds.borderColor = colors[i % 7];
            })

            const stagesCtxEl = document.getElementById('stagesChart') as any;
            const stagesCtx = stagesCtxEl.getContext('2d');
            this.stagesChart = new Chart(stagesCtx, {
                type: 'bar',
                data: {
                    labels: ['Deal Stage'],
                    datasets: stagesDatasets
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: {
                            ticks: {
                                callback: function(value, index, values) {
                                    if (Math.floor(value as number) === value) {
                                        return value;
                                    }
                                }
                            } 
                        }
                    },
                }
            });

            // Revenue chart
            const revCtxEl = document.getElementById('revenueChart') as any;
            const revCtx = revCtxEl.getContext('2d');
            this.revenueChart = new Chart(revCtx, {
                type: 'bar',
                data: {
                    labels: ['Total Dollar Amount'],
                    datasets: [
                        {
                            label: "Closed Won",
                            data: [this.wonRevenue],
                            borderColor: colors[0],
                            backgroundColor: colors[0]
                        },
                        {
                            label: "Potential",
                            data: [this.potentialRevenue],
                            borderColor: colors[1],
                            backgroundColor: colors[1],
                        },
                        {
                            label: "Closed Lost",
                            data: [this.lostRevenue],
                            borderColor: colors[2],
                            backgroundColor: colors[2],
                        },
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: {
                            ticks: {
                                callback: function(value, index, values) {
                                    return currencySymbol + value.toLocaleString();
                                }
                            } 
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const label = context.dataset.label || '';
                                    const value = parseFloat('' + context.dataset.data[0]).toLocaleString() || '0';
                                    return label + ': ' + currencySymbol + value;
                                }
                            }
                        }
                    }
                }
            });

            // Closed won bar
            const wonDatasets = [] as any;

            for (const [key, value] of Object.entries(statsData.perMonth)) {
                if(value) {
                    wonDatasets.push({
                        label: (this.months as any)[key],
                        data: [ value ]
                    })
                }
            }

            console.log(wonDatasets)

            wonDatasets.forEach((ds: any, i: number) => {
                ds.backgroundColor = colors[i % 7];
                ds.borderColor = colors[i % 7];
            })

            const closedWonCtxEl = document.getElementById('closedWonDeals') as any;
            const closedWonCtx = closedWonCtxEl.getContext('2d');
            this.closedWonChart = new Chart(closedWonCtx, {
                type: 'bar',
                data: {
                    labels: ['Month'],
                    datasets: wonDatasets
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: {
                            ticks: {
                                callback: function(value, index, values) {
                                    if (Math.floor(value as number) === value) {
                                        return currencySymbol + Math.floor(value as number).toLocaleString();
                                    }
                                }
                            } 
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const label = context.dataset.label || '';
                                    const value = parseFloat('' + context.dataset.data[0]).toLocaleString() || '0';
                                    return label + ': $' + value;
                                }
                            }
                        }
                    }
                }
            });
        }
    }
})
