





























import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AddEditVideo',
    props: ['video'],
    data() {
        return {
            formData: {
                videoId: -1,
                name: '',
                url: '',
                videoGroup: 'General',
                description: ''
            },
            isNew: true,
            isLoaded: false,
            disableButton: false,
            HSDocs: [],
            searchQ: '',
            groups: [] as string[]
        }
    },
    async created() {
        if(this.$props.video.VideoId != 'new') {
            this.isNew = false;
            this.formData.videoId = this.$props.video.VideoId;
            this.formData.name = this.$props.video.Name;
            this.formData.url = this.$props.video.URL;
            this.formData.videoGroup = this.$props.video.VideoGroup;
            this.formData.description = this.$props.video.Description;
        }
        await axios.get(`${this.$store.state.apiUrl}/admin/videos/groups`)
            .then(resp => {
                resp.data.forEach((group: any) => {
                    this.groups.push(group.Name)
                })
            })
            .catch(e => {
                console.log(e);
            })
        this.isLoaded = true;
    },
    methods: {
        async submit() {
            this.disableButton = true;
            if(!(this.formData as any).name) {
                alert("Name is required.");
                this.disableButton = false;
                return;
            }
            if(!(this.formData as any).url) {
                alert("URL is required.");
                this.disableButton = false;
                return;
            }

            let url = `${this.$store.state.apiUrl}/admin/videos`;
            if(!this.isNew) {
                url += `?id=${this.formData.videoId}`;
            } 
            await axios.post(url, this.formData)
                .then(() => {
                    if(this.isNew) {
                        alert("Video added.");
                    } else {
                        alert("Video updated.");
                    }
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert("There was an error.");
                })
            this.disableButton = false;
        },
        searchDocuments() {
            axios.get(`${this.$store.state.apiUrl}/admin/documents/hs?searchQ=${this.searchQ}`)
                .then(resp => {
                    this.HSDocs = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        },
        selectDocument(url: string) {
            this.formData.url = url;
        },
        closeEdit() {
            this.$emit('closeEdit');
        }
    }
})
