















































































import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';

export default Vue.extend({
    name: 'ViewDeal',
    props: ['id', 'stageLabel'],
    data() {
        return {
            dealId: -1,
            properties: [],
            displayProps: [] as any[],
            users: [],
            isLoaded: false,
            associatedCompanies: null as any,
            lineItems: [],
            products: [],
            notes: [] as any,
            emails: [] as any,
            calls: [] as any,
            meetings: [] as any,
            selectedView: 'deal'
        }
    },
    async created() {
        
        await axios.get(`${this.$store.state.apiUrl}/partner/deals/properties`)
            .then(resp => {
                this.properties = resp.data.properties;
            })
            .catch(e => {
                console.log(e);
            });
        
        let dealResp: any;
        await axios.get(`${this.$store.state.apiUrl}/partner/deals/${this.$props.id}`)
            .then(resp => {
                dealResp = resp;
            })
            .catch(e => {
                console.log(e);
            });

        if(dealResp) {
            if(this.$store.state.user.allowLeads && dealResp.data.associatedCompanies && dealResp.data.associatedCompanies.length > 0) {
                this.associatedCompanies = dealResp.data.associatedCompanies;
            }
            this.displayProps.push({
                name: "Create Date",
                value: new Date(dealResp.data.properties.createdate).toLocaleDateString()
            })
            if(dealResp.data.properties.dealname.indexOf(' - ') > -1) {
                this.displayProps.push({
                    name: "Deal Name",
                    value: dealResp.data.properties.dealname.replace(this.$store.state.user.name + ' - ', '').replace(' - ' + this.$store.state.user.name, '')
                });
            } else {
                this.displayProps.push({
                    name: "Deal Name",
                    value: dealResp.data.properties.dealname
                });
            }

            if(dealResp.data.lineItems) {
                await axios.get(`${this.$store.state.apiUrl}/products`)
                    .then(resp => {
                        this.products = resp.data;
                    })
                    .catch(e => {
                        console.log(e);
                    });
                this.lineItems = dealResp.data.lineItems;
                this.lineItems.forEach((lineItem: any) => {
                    const product = this.products.find((product: any) => {
                        return product.id == lineItem.productId;
                    }) as any;
                    if(product) {
                        lineItem.productName = product.properties.name;
                    } else {
                        lineItem.productName = lineItem.productId;
                    }
                })
            }
            
            let stageName = dealResp.data.properties.dealstage;
            if(this.$props.stageLabel) {
                stageName = this.$props.stageLabel;
            }
            this.displayProps.push({
                name: "Stage",
                value: stageName
            });
            for(const prop of this.properties) {
                if((prop as any).fieldType == 'hsuser') {
                    await axios.get(`${this.$store.state.apiUrl}/partner/deals/hsusers`)
                        .then(resp => {
                            this.users = resp.data;
                        })
                        .catch(e => {
                            console.log(e);
                        })

                    break;
                }
            }

            console.log(this.properties)
            this.properties.forEach((prop: any) => {
                if(dealResp.data.properties[prop.name]) {
                    if(prop.fieldType == 'hsuser') {
                        let userDisplay = dealResp.data.properties[prop.name];
                        const foundUser = this.users.find((u: any) => {
                            return u.id == userDisplay;
                        }) as any;
                        if(foundUser) {
                            userDisplay = foundUser.firstName + ' ' + foundUser.lastName;
                        }
                        this.displayProps.push({
                            name: prop.label,
                            value: userDisplay
                        });
                    } else if(prop.showCurrency ||
                        prop.name == 'amount' || 
                        prop.name == 'hs_tcv' || 
                        prop.name == 'hs_acv') {
                            let value = '';
                            const currency = dealResp.data.properties.deal_currency_code || this.$store.state.user.currency;
                            const amountSplit = dealResp.data.properties[prop.name].split('.');
                            value = amountSplit[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                            if(amountSplit[1] && parseInt(amountSplit[1]) != 0) {
                                const decimal = amountSplit[1].substring(0,2);
                                value += '.' + decimal;
                                if(decimal.length < 2) {
                                    value += '0';
                                }
                            }
                            if(currency == 'EUR') {
                                value = '€' + value;
                            } else if(currency == 'GBP') {
                                value = '£' + value;
                            } else {
                                value = '$' + value;
                            }
                            this.displayProps.push({
                                name: prop.label,
                                value: value
                            });
                    } else if(prop.fieldType == "checkbox") {
                        const checkedVals = dealResp.data.properties[prop.name].split(';');
                        let value = '';
                        for(let i = 0; i < checkedVals.length; i++) {
                            const cv = checkedVals[i];
                            const option = prop.options.find((option: any) => {
                                return option.value == cv;
                            });
                            if(option && option.label) {
                                value += option.label;
                            } else {
                                value += cv;
                            }
                            if(i < checkedVals.length - 1) {
                                value += '; ';
                            }
                        }
                        this.displayProps.push({
                            name: prop.label,
                            value: value
                        });
                    } else if(prop.fieldType == "select") {
                        let value = dealResp.data.properties[prop.name];
                        const option = prop.options.find((option: any) => {
                            return option.value == dealResp.data.properties[prop.name];
                        });
                        if(option && option.label) {
                            value = option.label;
                        }                     
                        this.displayProps.push({
                            name: prop.label,
                            value: value
                        });
                    } else if(prop.fieldType == "date") {                            
                        this.displayProps.push({
                            name: prop.label,
                            value: dealResp.data.properties[prop.name].split("T")[0]
                        });
                    } else {                            
                        this.displayProps.push({
                            name: prop.label,
                            value: dealResp.data.properties[prop.name]
                        });
                    }
                }
            });
        }

        this.getOtherInfo();
        
        this.isLoaded = true;
    },
    methods: {
        closeViewDeal() {
            this.$emit('closeViewDeal');
        },
        async getOtherInfo() {
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/notes/${this.$props.id}`)
                .then(resp => {
                    this.notes = [];
                    resp.data.forEach((note: any) => {
                        this.notes.push(note);
                    })
                    this.notes = this.notes.sort((a: any, b: any) => {
                        return new Date(a.createdAt).getTime() >= new Date(b.createdAt).getTime() ? -1 : 1;
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/emails/${this.$props.id}`)
                .then(resp => {
                    this.emails = resp.data;
                    this.emails = this.emails.sort((a: any, b: any) => {
                        return new Date(a.createdAt).getTime() >= new Date(b.createdAt).getTime() ? -1 : 1;
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/calls/${this.$props.id}`)
                .then(resp => {
                    this.calls = resp.data;
                    this.calls = this.calls.sort((a: any, b: any) => {
                        return new Date(a.timestamp).getTime() >= new Date(b.timestamp).getTime() ? -1 : 1;
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/meetings/${this.$props.id}`)
                .then(resp => {
                    this.meetings = resp.data;
                    this.meetings = this.meetings.sort((a: any, b: any) => {
                        return new Date(a.startTime).getTime() >= new Date(b.startTime).getTime() ? -1 : 1;
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }
})
