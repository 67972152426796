


















































import Vue from 'vue';
import axios from 'axios';
import InfoPop from '../../components/InfoPop.vue';
import AddEditPropertyDependency from '../../components/AddEditPropertyDependency.vue';
import AddEditPropertyDescription from '../../components/AddEditPropertyDescription.vue';
import draggable from 'vuedraggable';

export default Vue.extend({
    name: 'AdminDealSettings',
    components: {
        InfoPop: InfoPop,
        draggable: draggable,
        AddEditPropertyDependency: AddEditPropertyDependency,
        AddEditPropertyDescription: AddEditPropertyDescription
    },
    data() {
        return {
            allProperties: [],
            unselectedProperties: [],
            searchedProperties: [],
            formData: {
                selectedProperties: []
            },
            disableButton: false,
            propertySearchQ: '',
            showDependencyOptions: false,
            dependencySelectedProp: null,
            showEditDescription: false,
            descriptionSelectedProp: null
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            axios.get(`${this.$store.state.apiUrl}/admin/deal-settings`)
                .then(resp => {
                    console.log(resp.data)
                    this.allProperties = resp.data.allProperties;
                    this.formData.selectedProperties = resp.data.selectedProperties;
                    this.unselectedProperties = this.allProperties.filter((prop: any) => {
                        let isNotDup = true;
                        for(const selectedProp of this.formData.selectedProperties) {
                            if(prop.name == (selectedProp as any).name) {
                                isNotDup = false;
                                break;
                            }
                        }
                        return isNotDup;
                    });
                    this.searchedProperties = this.unselectedProperties;
                    this.$store.state.routeLoaded = true;
                })
                .catch(e => {
                    alert("There was an error retrieving Deal Settings, try refreshing.");
                    this.$store.state.routeLoaded = true;
                });
        },
        async submit() {
            this.disableButton = true;
            await axios.post(`${this.$store.state.apiUrl}/admin/deal-settings`, this.formData)
                .then(() => {
                    this.init();
                    alert("Deal Settings updated");
                })
                .catch(e => {
                    alert('There was an error, try resubmitting');
                })
            this.disableButton = false;
        },
        searchProperties() {
            if(this.propertySearchQ == '') {
                this.searchedProperties = this.unselectedProperties;
                return;
            }
            this.searchedProperties = this.unselectedProperties.filter((prop: any) => {
                return prop.label.toLowerCase().includes(this.propertySearchQ.toLowerCase())
                    || prop.name.toLowerCase().includes(this.propertySearchQ.toLowerCase())
            });
        },
        selectProperty(name: string) {
            const property = this.allProperties.find((prop: any) => {
                if(prop.name == name) {
                    return true;
                }
                return false;
            });
            (property as any).required = false;
            (this.formData.selectedProperties as any).push(property);
            this.unselectedProperties = this.unselectedProperties.filter((prop: any) => {
                return prop.name != name;
            });
            this.searchProperties();
        },
        removeProperty(name: string) {
            const property = this.allProperties.find((prop: any) => {
                if(prop.name == name) {
                    return true;
                }
                return false;
            });
            (this.unselectedProperties as any).push(property);
            this.formData.selectedProperties = this.formData.selectedProperties.filter((prop: any) => {
                return prop.name != name;
            });
            this.searchProperties();
        },
        closeDependencyPop() {
            this.showDependencyOptions = false;
            this.dependencySelectedProp = null;
        },
        closeDescriptionPop() {
            this.showEditDescription = false;
            this.descriptionSelectedProp = null;
        }
    }
})
