





















import Vue from 'vue';

export default Vue.extend({
    name: 'UserAddedDialog',
    props: ['email', 'password'],
    data() {
        return {
            copyMessage: 'Copy to clipboard'
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeUserAddedDialog');
        },
        copyToClipboard() {
            try {
                const el = document.createElement('textarea');
                el.value = this.$props.password;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                this.copyMessage = 'Copied!';
            } catch(e) {
                this.copyMessage = "Couldn't copy";
            }            
        }
    }
})
