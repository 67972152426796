























































































































































































import Vue from 'vue';
import axios from 'axios';
import UserAddedDialog from './UserAddedDialog.vue';
import InfoPop from './InfoPop.vue';

export default Vue.extend({
    name: 'EditUser',
    components: {
        UserAddedDialog: UserAddedDialog,
        InfoPop: InfoPop
    },
    props: ['userToEdit'],
    data() {
        return {
            name: '',
            allPipelines: [],
            shownPipelines: [],
            shownViewingPipelines: [],
            isNew: true,
            formData: {
                partnerName: '',
                firstName: '',
                lastName: '',
                contactId: '',
                email: '',
                userId: -1,
                customObjectId: '',
                hsOwnerId: '',
                isActive: true,
                documentGroups: [] as string[],
                videoGroups: [] as string[],
                pipelines: [] as string[],
                viewingPipelines: [] as string[],
                hiddenDealValues: [] as any
            },
            isLoaded: false,
            disableButton: false,
            generatedPassword: '',
            partners: [],
            partnerSearchQ: '',
            selectedPartner: {
                id: null,
                name: '',
                properties: {

                }
            },
            showUserAddedDialog: false,
            userAddedEmail: '',
            userAddedPassword: '',
            owners: [],
            searchedOwners: [],
            numItems: 30,
            groups: [] as string[],
            videoGroups: [] as string[],
            selectedGroup: '',
            selectedVideoGroup: '',
            selectedPipeline: null as any,
            selectedViewingPipeline: null as any,
            allProperties: [],
            unselectedProperties: [],
            searchedProperties: [],
            propertySearchQ: '',
            ownerSearchQ: '',
            selectedOwner: ''
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/admin/deal-settings`)
            .then(resp => {
                this.allProperties = resp.data.allProperties;
                this.unselectedProperties = this.allProperties;
                this.searchedProperties = this.allProperties;
            })
            .catch(e => {
                console.log(e);
            });

        await axios.get(`${this.$store.state.apiUrl}/admin/deal-settings/pipelines`)
            .then(resp => {
                this.allPipelines = resp.data;
                this.shownPipelines = this.allPipelines;
                this.shownViewingPipelines = this.allPipelines;
            })
            .catch(e => {
                console.log(e);
            });

        await axios.get(`${this.$store.state.apiUrl}/admin/partners/hsusers`)
            .then(resp => {
                this.owners = resp.data;
                this.searchedOwners = this.owners;
            })
            .catch(e => {
                console.log(e);
            });

        await axios.get(`${this.$store.state.apiUrl}/admin/documents/groups`)
            .then(resp => {
                resp.data.forEach((group: any) => {
                    this.groups.push(group.Name)
                })
            })
            .catch(e => {
                console.log(e);
            })

        await axios.get(`${this.$store.state.apiUrl}/admin/videos/groups`, { withCredentials: true})
            .then(resp => {
                resp.data.forEach((group: any) => {
                    this.videoGroups.push(group.Name);
                });
            })
            .catch(e => {
                console.log(e);
            })

        if(this.$store.state.user.partnerObjectId) {
            await this.searchPartners();
        }

        if(this.$props.userToEdit) {
            this.isNew = false;
            this.formData.partnerName = this.$props.userToEdit.PartnerName;
            this.formData.contactId = this.$props.userToEdit.ContactId;
            this.formData.email = this.$props.userToEdit.Email;
            this.formData.userId = this.$props.userToEdit.UserId;
            this.formData.isActive = this.$props.userToEdit.IsActive;
            this.formData.hsOwnerId = this.$props.userToEdit.HSOwnerId;
            if(this.formData.hsOwnerId) {
                console.log(this.owners);
                console.log(this.formData.hsOwnerId);
                const currentOwner = this.owners.find((o: any) => {
                    return o.id == this.formData.hsOwnerId;
                });
                if(currentOwner) {
                    this.selectOwner(currentOwner);
                }
            }
            const currentPipelines = this.$props.userToEdit.PipelineId.split(';');
            this.formData.pipelines = this.allPipelines.filter((p: any) => {
                return currentPipelines.includes(p.id);
            });
            this.shownPipelines = this.allPipelines.filter((pipeline: any) => {
                return !this.formData.pipelines.find((p: any) => {
                    return p.id == pipeline.id;
                });
            });
            if(this.$props.userToEdit.PipelinesToView) {
                const currentViewingPipelines = this.$props.userToEdit.PipelinesToView.split(';');
                this.formData.viewingPipelines = this.allPipelines.filter((p: any) => {
                    return currentViewingPipelines.includes(p.id);
                });
                this.shownViewingPipelines = this.allPipelines.filter((pipeline: any) => {
                    return !this.formData.viewingPipelines.find((p: any) => {
                        return p.id == pipeline.id;
                    });
                });
            }

            if(this.$props.userToEdit.CustomObjectId) {
                await this.findCurrentPartner(this.$props.userToEdit.CustomObjectId);
            }            

            await axios.get(`${this.$store.state.apiUrl}/admin/partners/partnerdocgroups/${this.$props.userToEdit.UserId}`)
                .then(resp => {
                    resp.data.forEach((group: any) => {
                        this.formData.documentGroups.push(group.Name as string);
                    })
                    this.groups = this.groups.filter(group => {
                        return !this.formData.documentGroups.includes(group);
                    })
                })
                .catch(e => {
                    console.log(e);
                })

            await axios.get(`${this.$store.state.apiUrl}/admin/partners/partnervideogroups/${this.$props.userToEdit.UserId}`)
                .then(resp => {
                    resp.data.forEach((group: any) => {
                        this.formData.videoGroups.push(group.Name as string);
                    })
                    this.videoGroups = this.videoGroups.filter(group => {
                        return !this.formData.videoGroups.includes(group);
                    })
                })
                .catch(e => {
                    console.log(e);
                })

            await axios.get(`${this.$store.state.apiUrl}/admin/partners/partnerhiddenvalues/${this.$props.userToEdit.UserId}`)
                .then(resp => {
                    this.formData.hiddenDealValues = resp.data;
                    this.unselectedProperties = this.unselectedProperties.filter((prop: any) => {
                        let alreadySelected = false;
                        for(const dv of this.formData.hiddenDealValues) {
                            if(dv.Name == prop.name) {
                                alreadySelected = true;
                                break;
                            }
                        }
                        return !alreadySelected;
                    })
                    this.searchedProperties = this.unselectedProperties;
                })
                .catch(e => {
                    console.log(e);
                })
        }
        this.isLoaded = true;
    },
    methods: {
        async submit() {
            this.disableButton = true;
            (this.formData as any).customObjectId = this.selectedPartner.id;

            if(this.formData.partnerName.includes(' - ')) {
                window.alert('Partner name cannot<br>include " - ".');
                this.disableButton = false;
                return;
            }

            for(const key in this.formData) {
                if(Object.prototype.hasOwnProperty.call(this.formData, key)) {
                    if(key == 'documentGroups' || key == 'isActive' || key == 'customObjectId' || 
                        key == 'contactId' || key == 'hsOwnerId' || key == 'userId' ||
                        key == 'firstName' || key == 'lastName') {
                        continue;
                    } else if(key == 'pipelines' && (this.formData as any)[key] && (this.formData as any)[key].length < 1) {
                        window.alert("At least one pipeline is required.")
                        this.disableButton = false;
                        return;
                    } else if(!(this.formData as any)[key]) {
                        window.alert("Missing required fields.")
                        this.disableButton = false;
                        return;
                    }
                }
            }
            
            if(this.isNew) {
                await axios.post(this.$store.state.apiUrl + '/admin/partners', this.formData, { withCredentials: true })
                    .then(resp => {
                        if(resp.data.isNewUser) {
                            this.userAddedEmail = this.formData.email;
                            this.userAddedPassword = resp.data.randPass;
                            this.showUserAddedDialog = true;
                        } else {
                            window.alert(`<b>${this.formData.email}</b> is an existing user.<br>They have been added to your portal.`)
                            this.$emit('closeEdit');
                        }
                    })
                    .catch(e => {
                        this.disableButton = false;
                        window.alert(e.response.data);
                    })
            } else {
                await axios.post(this.$store.state.apiUrl + '/admin/partners/update', this.formData, { withCredentials: true })
                    .then(resp => {
                        window.alert('User has been updated');
                        this.$emit('closeEdit');
                    })
                    .catch(e => {
                        this.disableButton = false;
                        window.alert(e.response.data);
                    })
            }
        },
        selectPartner(partner: any) {
            console.log(partner);
            this.selectedPartner = partner;
        },
        async searchPartners() {
            if(this.partnerSearchQ.length > 0) {
                await axios.get(`${this.$store.state.apiUrl}/admin/partnerobjects/search?searchQ=${this.partnerSearchQ}&limit=${this.numItems}`)
                    .then(resp => {
                        if(resp.data.length > 0) {
                            this.partners = resp.data;
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            } else {
                await axios.get(`${this.$store.state.apiUrl}/admin/partnerobjects?limit=${this.numItems}`)
                    .then(resp => {
                        console.log(resp.data);
                        this.partners = resp.data;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },
        async findCurrentPartner(objectId: any) {
            await axios.get(`${this.$store.state.apiUrl}/admin/partnerobjects/${objectId}`)
                .then(resp => {
                    this.selectedPartner = resp.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
        closeUserAddedDialog() {
            this.showUserAddedDialog = false;
            this.$emit('closeEdit');
        },
        addDocumentGroup() {
            this.formData.documentGroups.push(this.selectedGroup);
            this.groups = this.groups.filter(g => {
                return g != this.selectedGroup;
            })
        },
        removeDocumentGroup(group: string) {
            this.groups.push(group);
            this.formData.documentGroups = this.formData.documentGroups.filter(g => {
                return g != group;
            })
        },
        addVideoGroup() {
            this.formData.videoGroups.push(this.selectedVideoGroup);
            this.videoGroups = this.videoGroups.filter(g => {
                return g != this.selectedVideoGroup;
            })
        },
        removeVideoGroup(group: string) {
            this.videoGroups.push(group);
            this.formData.videoGroups = this.formData.videoGroups.filter(g => {
                return g != group;
            })
        },
        addPipeline() {
            this.formData.pipelines.push(this.selectedPipeline);
            this.shownPipelines = this.allPipelines.filter((pipeline: any) => {
                return !this.formData.pipelines.find((p: any) => {
                    return p.id == pipeline.id;
                });
            });
        },
        removePipeline(pipeline: any) {
            this.formData.pipelines = this.formData.pipelines.filter((p: any) => {
                return p.id != pipeline.id;
            })
            this.shownPipelines = this.allPipelines.filter((pipeline: any) => {
                return !this.formData.pipelines.find((p: any) => {
                    return p.id == pipeline.id;
                });
            });
        },
        addViewingPipeline() {
            this.formData.viewingPipelines.push(this.selectedViewingPipeline);
            this.shownViewingPipelines = this.allPipelines.filter((pipeline: any) => {
                return !this.formData.viewingPipelines.find((p: any) => {
                    return p.id == pipeline.id;
                });
            });
        },
        removeViewingPipeline(pipeline: any) {
            this.formData.viewingPipelines = this.formData.viewingPipelines.filter((p: any) => {
                return p.id != pipeline.id;
            })
            this.shownViewingPipelines = this.allPipelines.filter((pipeline: any) => {
                return !this.formData.viewingPipelines.find((p: any) => {
                    return p.id == pipeline.id;
                });
            });
        },
        searchProperties() {
            if(this.propertySearchQ == '') {
                this.searchedProperties = this.unselectedProperties;
                return;
            }
            this.searchedProperties = this.unselectedProperties.filter((prop: any) => {
                return prop.label.toLowerCase().includes(this.propertySearchQ.toLowerCase())
                    || prop.name.toLowerCase().includes(this.propertySearchQ.toLowerCase())
            });
        },
        selectProperty(name: string) {
            const property = this.allProperties.find((prop: any) => {
                if(prop.name == name) {
                    return true;
                }
                return false;
            });
            (property as any).required = false;
            (this.formData.hiddenDealValues as any).push({
                Name: name,
                Value: ''
            });
            this.unselectedProperties = this.unselectedProperties.filter((prop: any) => {
                return prop.name != name;
            });
            this.searchProperties();
        },
        removeHiddenDealValue(name: string) {
            const property = this.allProperties.find((prop: any) => {
                if(prop.name == name) {
                    return true;
                }
                return false;
            });
            (this.unselectedProperties as any).push(property);
            this.formData.hiddenDealValues = this.formData.hiddenDealValues.filter((dv: any) => {
                return dv.Name != name;
            });
            this.searchProperties();
        },
        searchOwners() {
            this.searchedOwners = this.owners.filter((owner: any) => {
                return owner.email.toLowerCase().includes(this.ownerSearchQ.toLowerCase());
            })
        },
        selectOwner(owner: any) {
            this.selectedOwner = owner.email;
            this.formData.hsOwnerId = owner.id;
        }
    }
})
