





































































































import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AddEditDeal',
    props: ['id'],
    data() {
        return {
            dealId: -1,
            properties: [],
            products: [],
            stages: [],
            users: [],
            formData: {
                properties: {
                    dealstage: ''
                },
                associatedCompanies: [],
                associatedContacts: [],
                lineItems: [],
                lineItemsToRemove: [] as number[],
                pipelineId: ''
            },
            isNew: true,
            isLoaded: false,
            disableButton: false,
            allowLeads: false,
            allowLineItems: false,
            allowLineItemCustomPrice: false,
            companySearchQ: '',
            searchedCompanies: [],
            contactSearchQ: '',
            searchedContacts: [],
            pipelines: [] as any,
            showDealStage: true,
            requireLineItems: false,
            searchCompanyDebounceTimer: null as any,
            searchContactDebounceTimer: null as any,
            showAssociatedContactSection: false,
            showAssociatedCompanySection: false
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/partner/deals/settings`)
            .then(resp => {
                this.allowLeads = resp.data.allowLeads;
                this.allowLineItems = resp.data.allowLineItems;
                this.allowLineItemCustomPrice = resp.data.allowLineItemCustomPrice;
                this.pipelines = resp.data.pipelines;
                this.formData.pipelineId = this.pipelines[0].id;
                this.requireLineItems = resp.data.requireLineItems;
                if(resp.data.defaultDealStage) {
                    this.showDealStage = false;
                    if(this.$props.id == 'new') {
                        this.formData.properties.dealstage = resp.data.defaultDealStage;
                    }
                }
            })
            .catch(e => {
                console.log(e);
            })
        await axios.get(`${this.$store.state.apiUrl}/partner/deals/properties?editing=true`)
            .then(resp => {
                this.properties = resp.data.properties;
            })
            .catch(e => {
                console.log(e);
            });
        if(this.allowLeads) {
            this.searchCompanies();
            this.searchContacts();
        }
        if(this.allowLineItems || this.requireLineItems) {
            await axios.get(`${this.$store.state.apiUrl}/products`)
                .then(resp => {
                    this.products = resp.data;
                })
                .catch(e => {
                    console.log(e);
                });
        }
        if(this.$props.id != 'new') {
            this.isNew = false;
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/${this.$props.id}`)
                .then(resp => {
                    if(resp.data.associatedCompanies) {
                        this.formData.associatedCompanies = resp.data.associatedCompanies;
                    }
                    if(resp.data.associatedContacts) {
                        this.formData.associatedContacts = resp.data.associatedContacts;
                    }
                    if(resp.data.properties.dealname.indexOf(' - ') > -1) {
                        (this.formData.properties as any).dealname = resp.data.properties.dealname.replace(this.$store.state.user.name + ' - ', '').replace(' - ' + this.$store.state.user.name, '');
                    } else {
                        (this.formData.properties as any).dealname = resp.data.properties.dealname;
                    }
                    
                    (this.formData.properties as any).dealstage = resp.data.properties.dealstage;
                    this.formData.pipelineId = resp.data.properties.pipeline;
                    this.properties.forEach((prop: any) => {
                        if(resp.data.properties[prop.name]) {
                            if(prop.fieldType == "checkbox") {
                                (this.formData as any).properties[prop.name] = [];
                                const checkedVals = resp.data.properties[prop.name].split(';');
                                checkedVals.forEach((cv: any) => {
                                    if(this.$refs[prop.name+'_'+cv] && (this.$refs[prop.name+'_'+cv] as any)[0]) {
                                        (this.formData as any).properties[prop.name].push(cv);
                                        (this.$refs[prop.name+'_'+cv] as any)[0].checked = true;
                                    }
                                });
                            } else if(prop.fieldType == "date" && (this.$refs[prop.name] as any)[0]) {
                                try {
                                    this.setDate(resp.data.properties[prop.name].split("T")[0], prop.name);
                                    (this.$refs[prop.name] as any)[0].value = resp.data.properties[prop.name].split("T")[0];
                                } catch(e) { 
                                    console.log(e);
                                }                                
                            } else {
                                (this.formData.properties as any)[prop.name] = resp.data.properties[prop.name];
                            }
                        }
                    });
                    if(resp.data.lineItems) {
                        this.formData.lineItems = resp.data.lineItems;
                    }
                })
                .catch(e => {
                    console.log(e);
                })
        }
        let hasHSUserField = false;
        for(const prop of this.properties) {
            if((prop as any).fieldType == 'hsuser') {
                hasHSUserField = true;
                break;
            }
        }
        if(hasHSUserField) {
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/hsusers`)
                .then(resp => {
                    this.users = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        }
        
        await this.getStages(true);
        this.checkDependentFields();
        this.isLoaded = true;
    },
    methods: {
        async submit() {
            this.disableButton = true;
            if(!(this.formData as any).properties.dealname) {
                alert("Deal name is required.");
                this.disableButton = false;
                return;
            }
            if(!(this.formData as any).properties.dealstage) {
                alert("Deal stage is required.");
                this.disableButton = false;
                return;
            }
            if(this.requireLineItems &&  (!(this.formData as any).lineItems || (this.formData as any).lineItems.length < 1)) {
                alert("At least one line item is required.");
                this.disableButton = false;
                return;
            }
            for(const prop of this.properties) {
                if((prop as any).required && (prop as any).show && !(this.formData as any).properties[(prop as any).name]) {
                    alert("Please fill in all required fields.");
                    this.disableButton = false;
                    return;
                }
            }

            // Removing hidden fields
            for(const prop of this.properties) {
                if(!(prop as any).show && (this.formData as any).properties[(prop as any).name]) {
                    delete (this.formData as any).properties[(prop as any).name];
                }
            }

            let url = `${this.$store.state.apiUrl}/partner/deals`;
            if(!this.isNew) {
                url += `?id=${this.$props.id}`;
            } 
            await axios.post(url, this.formData)
                .then(() => {
                    if(this.isNew) {
                        alert("Deal added.");
                    } else {
                        alert("Deal updated.");
                    }
                    this.$emit('closeEdit');
                })
                .catch(e => {
                    alert(e.response.data);
                    this.disableButton = false;
                })
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
        multiSelectChange(propName: any, event: any) {
            if(!(this.formData as any).properties[propName]) {
                (this.formData as any).properties[propName] = [];
            }
            if(event.srcElement.checked) {
                (this.formData as any).properties[propName].push(event.srcElement.value);
            } else {
                (this.formData as any).properties[propName] = (this.formData as any).properties[propName].filter((option: any) => {
                    return option != event.srcElement.value;
                })
            }
        },
        setDate(dateString: string, propName: string) {
            const date = new Date(dateString);
            // Date fields use UTC Midnight timestamps
            let dateUTC =  date.getTime();
            // Close date does not, and uses timezone offset in regards to who is viewing
            if(propName == "closedate") {
                dateUTC = date.getTime() + date.getTimezoneOffset()*60*1000;
            }
            (this.formData as any).properties[propName] = dateUTC;
        },
        debounceSearchCompanies() {
            clearTimeout(this.searchCompanyDebounceTimer);
            this.searchCompanyDebounceTimer = setTimeout(() => { this.searchCompanies() }, 500);
        },
        searchCompanies() {
            if(this.companySearchQ.length > 0) {
                axios.get(`${this.$store.state.apiUrl}/partner/companies?numItems=20&searchQ=${this.companySearchQ}`)
                    .then(resp => {
                        this.searchedCompanies = resp.data.results;
                    })
                    .catch(e => {
                        console.log(e);
                    })
            } else {
                axios.get(`${this.$store.state.apiUrl}/partner/companies?numItems=20`)
                    .then(resp => {
                        this.searchedCompanies = resp.data.results;
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },
        selectCompany(company: any) {
            const existingCompany = (this.formData.associatedCompanies as any).find((comp: any) => {
                return comp.id == company.id;
            });
            if(existingCompany) {
                return;
            }
            (this.formData.associatedCompanies as any).push({
                name: company.properties.name,
                id: company.id,
                city: company.properties.city
            });
        },
        removeCompany(id: number) {
            (this.formData.associatedCompanies as any) = (this.formData.associatedCompanies as any).filter((contact: any) => {
                return contact.id != id;
            })
        },
        debounceSearchContacts() {
            clearTimeout(this.searchContactDebounceTimer);
            this.searchContactDebounceTimer = setTimeout(() => { this.searchContacts() }, 500);
        },
        searchContacts() {
            if(this.contactSearchQ.length > 0) {
                axios.get(`${this.$store.state.apiUrl}/partner/contacts?numItems=20&searchQ=${this.contactSearchQ}`)
                    .then(resp => {
                        this.searchedContacts = resp.data.results;
                    })
                    .catch(e => {
                        console.log(e);
                    })
            } else {
                axios.get(`${this.$store.state.apiUrl}/partner/contacts?numItems=20`)
                    .then(resp => {
                        this.searchedContacts = resp.data.results;
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
        },
        selectContact(contact: any) {
            const existingContact = (this.formData.associatedContacts as any).find((c: any) => {
                return c.id == contact.id;
            });
            if(existingContact) {
                return;
            }
            (this.formData.associatedContacts as any).push({
                id: contact.id,
                displayName: contact.properties.lastname + ', ' + contact.properties.firstname
            })
        },
        removeContact(id: any) {
            (this.formData.associatedContacts as any) = (this.formData.associatedContacts as any).filter((contact: any) => {
                return contact.id != id;
            })
        },
        checkDependentFields() {
            Vue.nextTick(() => {       
                const self = this as any;
                this.properties.forEach((property: any) => {
                    // Checking if dependent field is hidden first, if it is so should the prop itself
                    if(property.isDependent) {
                        const dependentProp = this.properties.find((prop: any) => {
                            return prop.name == property.dependentId;
                        }) as any;
                        if(dependentProp && !dependentProp.show) {
                            property.show = false;
                            return;
                        }
                    }
                    
                    if(!property.isDependent || 
                        (property.dependencyType == 'EQUALS' && self.formData.properties[property.dependentId] && property.dependencyValue && self.formData.properties[property.dependentId].toString() == property.dependencyValue.toString()) ||
                        (property.dependencyType == 'CONTAINS' && self.formData.properties[property.dependentId] && property.dependencyValue && self.formData.properties[property.dependentId].toString().toLowerCase().includes(property.dependencyValue.toString().toLowerCase())) ||
                        (property.dependencyType == 'GT' && self.formData.properties[property.dependentId] && property.dependencyValue && self.formData.properties[property.dependentId] > property.dependencyValue) ||
                        (property.dependencyType == 'LT' && self.formData.properties[property.dependentId] && property.dependencyValue && self.formData.properties[property.dependentId] < property.dependencyValue))
                    {
                        property.show = true;
                    } else if(property.dependencyType == 'ISONEOF' && self.formData.properties[property.dependentId] && property.dependencyValue) {
                        let isMatch = false;
                        const options = property.dependencyValue.toString().split(';');
                        // For multi select arrays
                        if(Array.isArray(self.formData.properties[property.dependentId])) {
                            for(const val of self.formData.properties[property.dependentId]) {
                                for(const option of options) {
                                    if(val == option) {
                                        isMatch = true;
                                        break;
                                    }
                                }
                                if(isMatch) break;
                            }
                        } else {
                            for(const option of options) {
                                if(self.formData.properties[property.dependentId] == option) {
                                    isMatch = true;
                                    break;
                                }
                            }
                        }
                        property.show = isMatch;
                    } else {
                        property.show = false;
                    }

                    // This was used for nulling hidden properties, this can't be used because it breaks
                    // editing deals by removing past properties
                    /*if(!property.show) {
                        // Unchecking multi select array check boxes
                        if(Array.isArray(self.formData.properties[property.name])) {
                            for(const val of self.formData.properties[property.name]) {
                                self.$refs[property.name+'_'+val][0].checked = false;
                            }
                        }
                        // Clearing date
                        if(self.$refs[property.name] 
                            && self.$refs[property.name][0] 
                            && self.$refs[property.name][0].value) {
                                console.log(self.$refs[property.name][0].value);
                                self.$refs[property.name][0].value = null;
                        }
                        self.formData.properties[property.name] = null;
                    }*/
                })
                this.$forceUpdate();
            });
        },
        removeLineItem(index: number) {
            const lineItem = this.formData.lineItems.splice(index, 1)[0] as any;
            if(lineItem.id) {
                this.formData.lineItemsToRemove.push(lineItem.id);
            }
        },
        async getStages(init?: boolean) {
            await axios.get(`${this.$store.state.apiUrl}/partner/deals/stages/${this.formData.pipelineId}`)
                .then(resp => {
                    this.stages = resp.data;
                    // Clearing out deal stage when switching pipelines
                    if(!init) {
                        (this.formData as any).properties.dealstage = null;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
    },
    watch: {
        'formData.properties': {
            handler(val) {
                this.checkDependentFields();
            },
            deep: true
        }
    }
})
