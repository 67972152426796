



































import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'AddEditCompany',
    props: ['id'],
    data() {
        return {
            properties: [],
            users: [],
            formData: {
                properties: {
                    domain: '',
                    name: ''
                },
            },
            isLoaded: false,
            disableButton: false,
            isNew: true
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/partner/companies/properties`)
            .then(resp => {
                this.properties = resp.data.companyProperties;
            })
            .catch(e => {
                console.log(e);
            });

        let hasHSUserField = false;
        for(const prop of this.properties) {
            if((prop as any).fieldType == 'hsuser') {
                hasHSUserField = true;
                break;
            }
        }
        if(hasHSUserField) {
            await axios.get(`${this.$store.state.apiUrl}/partner/leads/hsusers`)
                .then(resp => {
                    this.users = resp.data;
                })
                .catch(e => {
                    console.log(e);
                })
        }

        console.log(this.$props.id);
        if(this.$props.id != 'new') {
            this.isNew = false;
            await axios.get(`${this.$store.state.apiUrl}/partner/companies/${this.$props.id}`)
                .then(resp => {
                    (this.formData.properties as any).domain = resp.data.properties.domain;
                    (this.formData.properties as any).name = resp.data.properties.name;
                    this.properties.forEach((prop: any) => {
                        if(resp.data.properties[prop.name]) {
                            if(prop.fieldType == "checkbox") {
                                (this.formData as any).properties[prop.name] = [];
                                const checkedVals = resp.data.properties[prop.name].split(';');
                                checkedVals.forEach((cv: any) => {
                                    if(this.$refs[prop.name+'_'+cv] && (this.$refs[prop.name+'_'+cv] as any)[0]) {
                                        (this.formData as any).properties[prop.name].push(cv);
                                        (this.$refs[prop.name+'_'+cv] as any)[0].checked = true;
                                    }
                                });
                            } else if(prop.fieldType == "date" && (this.$refs[prop.name] as any)[0]) {
                                try {
                                    this.setDate(resp.data.properties[prop.name].split("T")[0], prop.name);
                                    (this.$refs[prop.name] as any)[0].value = resp.data.properties[prop.name].split("T")[0];
                                } catch(e) { 
                                    console.log(e);
                                }
                            } else {
                                (this.formData.properties as any)[prop.name] = resp.data.properties[prop.name];
                            }
                        }
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        }
        
        this.isLoaded = true;
    },
    methods: {
        async submit() {
            this.disableButton = true;
            if(!(this.formData as any).properties.domain) {
                alert("Domain is required.");
                this.disableButton = false;
                return;
            }
            if(!(this.formData as any).properties.name) {
                alert("Name is required.");
                this.disableButton = false;
                return;
            }
            for(const prop of this.properties) {
                if((prop as any).required && !(this.formData as any).properties[(prop as any).name]) {
                    alert("Please fill in all required fields.");
                    this.disableButton = false;
                    return;
                }
            }

            let url = `${this.$store.state.apiUrl}/partner/companies`;
            if(!this.isNew) {
                url += `?id=${this.$props.id}`;
            } 
            await axios.post(url, this.formData)
                .then(resp => {
                    if(this.isNew) {
                        alert("Company added.");
                    } else {
                        alert("Company updated.");
                    }                    
                    this.$emit('closeEdit', resp.data.id, resp.data.name);
                })
                .catch(e => {
                    alert(e.response.data);
                    this.disableButton = false;
                })
        },
        closeEdit() {
            this.$emit('closeEdit');
        },
        multiSelectChange(propName: any, event: any) {
            if(!(this.formData as any).properties[propName]) {
                (this.formData as any).properties[propName] = [];
            }
            if(event.srcElement.checked) {
                (this.formData as any).properties[propName].push(event.srcElement.value);
            } else {
                (this.formData as any).properties[propName] = (this.formData as any).properties[propName].filter((option: any) => {
                    return option != event.srcElement.value;
                })
            }
        },
        setDate(dateString: string, propName: string) {
            const date = new Date(dateString);
            // Date fields use UTC Midnight timestamps
            let dateUTC =  date.getTime();
            // Close date does not, and uses timezone offset in regards to who is viewing
            if(propName == "closedate") {
                dateUTC = date.getTime() + date.getTimezoneOffset()*60*1000;
            }
            (this.formData as any).properties[propName] = dateUTC;
        }
    }
})
