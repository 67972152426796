














import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'ResetPasswordDialog',
    data() {
        return {
            disableButton: false,
            formData: {
                email: ''
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeResetPassword');
        },
        submit() {
            axios.post(`${this.$store.state.apiUrl}/user/login/resetpass`, this.formData)
                .then(resp => {
                    this.$emit('closeResetPassword');
                    alert("Password reset request submitted. You should receive an email with a temporary link shortly. Please try again or contact your portal administrator if you have any issues.");
                })
                .catch(e => {
                    alert("There was an error. Please try again or contact your portal administrator if you have any issues.");
                });
        }
    }
})
