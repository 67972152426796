




















import Vue from 'vue';
import axios from 'axios';

export default Vue.extend({
    name: 'viewContactCompany',
    props: ['id', 'type'],
    data() {
        return {
            properties: [ ] as any,
            displayProps: [] as any[],
            isLoaded: false,
            associatedCompany: ''
        }
    },
    async created() {
        await axios.get(`${this.$store.state.apiUrl}/partner/${this.$props.type}/properties`)
            .then(resp => {
                if(this.$props.type == 'contacts') {
                    this.properties = [
                        {
                            name: 'firstname',
                            label: 'First Name'
                        },
                        {
                            name: 'lastname',
                            label: 'Last Name'
                        },
                        {
                            name: 'email',
                            label: 'Email'
                        }
                    ]
                    resp.data.contactProperties.forEach((cp: any) => {
                        this.properties.push(cp);
                    })
                } else {
                    this.properties = [
                        {
                            name: 'name',
                            label: 'Name'
                        },
                        {
                            name: 'domain',
                            label: 'Domain'
                        }
                    ]
                    resp.data.companyProperties.forEach((cp: any) => {
                        this.properties.push(cp);
                    })
                }                
            })
            .catch(e => {
                console.log(e);
            });

        await axios.get(`${this.$store.state.apiUrl}/partner/${this.$props.type}/${this.$props.id}`)
            .then(resp => {
                if(resp.data.associatedCompany) {
                    this.associatedCompany = resp.data.associatedCompany.properties.name;
                }
                console.log(resp.data)
                this.properties.forEach((prop: any) => {
                    if(resp.data.properties[prop.name]) {
                        if(prop.fieldType == "checkbox") {
                            const checkedVals = resp.data.properties[prop.name].split(';');
                            let value = '';
                            for(let i = 0; i < checkedVals.length; i++) {
                                const cv = checkedVals[i];
                                const option = prop.options.find((option: any) => {
                                    return option.value == cv;
                                });
                                if(option && option.label) {
                                    value += option.label;
                                } else {
                                    value += cv;
                                }
                                if(i < checkedVals.length - 1) {
                                    value += '; ';
                                }
                            }
                            this.displayProps.push({
                                name: prop.label,
                                value: value
                            });
                        } else if(prop.fieldType == "select") {
                            let value = resp.data.properties[prop.name];
                            const option = prop.options.find((option: any) => {
                                return option.value == resp.data.properties[prop.name];
                            });
                            if(option && option.label) {
                                value = option.label;
                            }                     
                            this.displayProps.push({
                                name: prop.label,
                                value: value
                            });
                        } else if(prop.fieldType == "date") {                            
                            this.displayProps.push({
                                name: prop.label,
                                value: resp.data.properties[prop.name].split("T")[0]
                            });
                        } else {                            
                            this.displayProps.push({
                                name: prop.label,
                                value: resp.data.properties[prop.name]
                            });
                        }
                    }
                });
            })
            .catch(e => {
                console.log(e);
            });
        
        this.isLoaded = true;
    }
})
