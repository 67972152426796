



















































import Vue from 'vue';
import axios from 'axios';
import ConfirmDelete from '../components/ConfirmDelete.vue';
import AddEditVideo from '../components/AddEditVideo.vue';
import AddEditVideoGroups from '../components/AddEditVideoGroups.vue';

export default Vue.extend({
    name: 'Videos',
    components: {
        ConfirmDelete: ConfirmDelete,
        AddEditVideo: AddEditVideo,
        AddEditVideoGroups: AddEditVideoGroups
    },
    data() {
        return {
            videos: [],
            stageLabels: {},
            searchQ: '',
            pagination: [] as number[],
            showConfirmDelete: false,
            idToDelete: -1,
            showEditVideo: false,
            videoToEdit: { 
                VideoId: 'new',
                Name: '',
                URL: '',
                VideoGroup: '',
                Description: ''
            },
            numItems: 6,
            currentPageNum: 1,
            totalItems: 0,
            showEditGroups: false,
            groups: ['General'],
            group: 'General'
        }
    },
    async created() {
        await this.getVideos();
        this.getVideoGroups();
        this.$store.state.routeLoaded = true;
    },
    methods: {
        async getVideos() {
            let url = `${this.$store.state.apiUrl}/partner/videos?pageNum=${this.currentPageNum}&numItems=${this.numItems}&group=${this.group}`;
            if(this.$store.state.user.isHSAdmin) {
                url = `${this.$store.state.apiUrl}/admin/videos?pageNum=${this.currentPageNum}&numItems=${this.numItems}&group=${this.group}`;
            }
            if(this.searchQ && this.searchQ.length > 2) {
                url += '&searchQ=' + this.searchQ;
            }
            await axios.get(url, { withCredentials: true })
                .then(resp => {
                    this.videos = resp.data.videos;
                        
                    this.pagination = [];
                    this.totalItems = resp.data.total;
                    if(this.currentPageNum - 2 > 0) {
                        this.pagination.push(this.currentPageNum - 2);
                    }
                    if(this.currentPageNum - 1 > 0) {
                        this.pagination.push(this.currentPageNum - 1);
                    }
                    this.pagination.push(this.currentPageNum);
                    if((this.currentPageNum * this.numItems) < this.totalItems) {
                        this.pagination.push(this.currentPageNum + 1);
                    }
                    if(((this.currentPageNum+1) * this.numItems) < this.totalItems) {
                        this.pagination.push(this.currentPageNum + 2);
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
        getVideoGroups() {
            this.groups = ['General'];
            let url = `${this.$store.state.apiUrl}/partner/videos/groups`;
            if(this.$store.state.user.isHSAdmin) {
                url = `${this.$store.state.apiUrl}/admin/videos/groups`;
            }
            axios.get(url, { withCredentials: true})
                .then(resp => {
                    resp.data.forEach((group: any) => {
                        this.groups.push(group.Name);
                    });
                })
                .catch(e => {
                    console.log(e);
                })
        },
        getVideosReset() {
            this.currentPageNum = 1;
            this.getVideos();
        },
        changePage(pageNum: number) {
            this.currentPageNum = pageNum;
            this.getVideos();
        },
        editVideo(video: any) {
            if(video == 'new') {
                (this.videoToEdit as any) = { 
                    VideoId: 'new',
                    Name: '',
                    URL: '',
                    VideoGroup: '',
                    Description: ''
                }
            } else {
                this.videoToEdit = video;
            }
            this.showEditVideo = true;
        },
        closeEdit() {
            this.showEditVideo = false;
            setTimeout(() => { this.getVideosReset() }, 500);
        },
        confirmDelete(id: number) {
            this.showConfirmDelete = true;
            this.idToDelete = id;
        },
        confirmDeleteCB() {
            this.showConfirmDelete = true;
            axios.delete(`${this.$store.state.apiUrl}/admin/videos/${this.idToDelete}`)
                .then(resp => {
                    alert("Delete Successful");
                    this.showConfirmDelete = false;
                    this.getVideosReset();
                })
                .catch(e => {
                    this.showConfirmDelete = false;
                    alert(e.response.data);
                })
        },
        cancelDeleteCB() {
            // this.idToDelete = -1;
            this.showConfirmDelete = false;
        }
    }
})
