










import Vue from 'vue';

export default Vue.extend({
    name: 'InfoPop',
    props: ['message'],
    data() {
        return {
            showMessage: false
        }
    },
})
